import React,{ useState, useEffect, useRef } from 'react'
import BreadCrump from '../../components/BreadCrump'
import { Button } from 'primereact/button';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { ProductService } from '../../service/ProductService';
import { VariantService } from '../../service/VariantService';
import { OptionsService } from '../../service/OptionsService';
import { ImageService } from '../../service/ImageService';
import { FileUpload } from 'primereact/fileupload';
import { InputTextarea } from 'primereact/inputtextarea';
import { v4 as uuidv4 } from 'uuid';
import { useLocation , useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';

//------------------------------ FORM ---------------------------------------------- 
import OptionsBlock from '../../components/products/Add_product/OptionsBlock';
import PriceBlock from '../../components/products/Add_product/PriceBlock';
import StockBlock from '../../components/products/Add_product/StockBlock';
import CategoryBlock from '../../components/products/Add_product/CategoryBlock';
import TagsBlock from '../../components/products/Add_product/TagsBlock';
import CustomInput from '../../components/CustomInput';
import CustomTextArea from '../../components/CustomTextArea';
import CustomSwitch from '../../components/CustomSwitch';




const AddProduct = () => {

    const productService = new ProductService()
    const optionsService = new OptionsService()
    const variantService = new VariantService()
    
    const breadCrumpItems={
        href:'products',
        parentName:'produits',
        currentPage:'ajouter nouveau produit'
      }
    const marginBottom = 25
    const toast = useRef(null);
    const navigate = useNavigate()
    const [options,setOptions]=useState([]) // OPTIONS
    const [variants,setVariants]=useState([]) // VARIANTS
    
    
    const [totalSize, setTotalSize] = useState(0)
    const [loading, setloading] = useState(false) 
    let fileUploadRef = useRef(null);

    const initialValues = {
        reference: '',
        nameProduct: '',
        selectedCategories: null,
        selectedUnderCategories: null,
        priceProduct:0,
        discountPercentage:0,
        tags:[],
        description:'',
        quantityStock:0,
        minOrderQuantity: 1,
        active:true,
        hasVariant:false
    }
    

    //-----------handle template images-------------//
    //when the selected images uploaded
    const myUploader = async(values,event) => {
        let firebaseUrl = []
        const imageService = new ImageService()
        const files = event.files
        
        for(let i =0; i < files.length; i++ ){
            const blob = await fetch(files[i].objectURL).then(r => r.blob()); //get blob url
            const url_product = await imageService.uploadImage(blob,`products/${files[i].name}${uuidv4()}`) // upload to firebase and get url
            firebaseUrl.push(url_product.data)
        }
        values.photos = firebaseUrl
        await _addProduct(values)

    }

    // when an image added
    const onTemplateSelect = (e) => {
        setTotalSize(e.files[0].size + totalSize)
    }

    // when an image removed
    const onTemplateRemove = (e) => {
        setTotalSize(totalSize - e.file.size)
    }

    // when all images removed
    const onTemplateClear = () => {
        setTotalSize(0)
    }

    // CALCULATE OPTION TOTAL QUANTITY
    const calculateTotalOptionValues=()=>{
        let newArr=[...options]
        let indexVal
        variants.map((_var,index)=>{
            if(_var.option1){
                indexVal =  newArr[0].values.findIndex((v) => v.name === _var.option1.value)
                if(indexVal !== -1 ){
                    newArr[0].values[indexVal].quantityStock += Number(_var.quantityStock)
                }}
            
              if(_var.option2){
                indexVal =  newArr[1].values.findIndex((v) => v.name === _var.option2.value)
                
             if(indexVal !== -1 ){
              newArr[1].values[indexVal].quantityStock += Number(_var.quantityStock)
            }}

            
        })
        setOptions(newArr)
    }

    // PUSH OPTION ID TO EACH VARIANT
    const pushIdToVariant=(responseOpt)=>{
        let opt1, opt2, opt3
        let newArr = [...variants]
        
        newArr.map((_var,index)=>{
            if(_var.option1){
                if(typeof opt1 === 'undefined')
                opt1 = responseOpt.find(op => op.numOption === 1)
                newArr[index].option1.optionId = opt1._id
            }
            if(_var.option2){
                if(typeof opt2 === 'undefined')
                opt2 = responseOpt.find(op => op.numOption === 2)
                newArr[index].option2.optionId = opt2._id
            }
        })
        setVariants(newArr)
    }

    //create products
    async function onSubmit(values,actions){
        if(values.hasVariant && options.length === 0){
            toast.current.show({severity:'warn', summary: 'Warning', detail:'ajouter des options', life: 3000});
        }else{
            fileUploadRef.upload()
        }
    }


    async function _addProduct(values){
        setloading(true)
        const product = {
            nameProduct: values.nameProduct,
            category: values.selectedCategories,
            underCategory: values.selectedUnderCategories,
            description: values.description,
            photos: values.photos,
            active: values.active,
            tags:values.tags,
            hasVariant:values.hasVariant
        }

        console.log('product',product)
        
        const response = await productService.addProduct(product)
        if(response.data){
            if(!values.hasVariant){
                const singleVariant=[
                    {
                        product: response.data._id,
                        reference: values.reference,
                        quantityStock: values.quantityStock,
                        minOrderQuantity: values.minOrderQuantity,
                        priceProduct:Number(values.priceProduct),
                        discountPercentage: Number(values.discountPrice),
                    }
                ]
                console.log('singleVariant',singleVariant)
                await addVariant(singleVariant)
            }else{
                calculateTotalOptionValues()
                for(let i in options){
                  options[i].product = response.data._id 
                }
                const optionsRes = await optionsService.addOption(options) 
                if(optionsRes.data){
                  pushIdToVariant(optionsRes.data) 
                }else{
                  toast.current.show({severity:'error', summary: 'Error', detail:'erreur options essayer plus tard', life: 3000});
                }

                for(let i in variants){
                  variants[i].product = response.data._id 
                }
                await addVariant(variants)
                navigate('/products')
                console.log('variants',variants)
            }
        } else {
            setloading(false)
            toast.current.show({severity:'error', summary: 'Error', detail:'erreur essayer plus tard', life: 3000});
            console.log(response.error)
        }
    }


    async function addVariant(variant){
        const response = await variantService.addVariant(variant)
        if(response.data){
            console.log('variant added')
            navigate('/products')
        }else{
            console.log(response.error)
            toast.current.show({severity:'error', summary: 'Error', detail:'erreur ajout variant', life: 3000});
        }
        return response
    }

    const validationSchema = Yup.object().shape({
        reference: Yup.string().when('hasVariant', {
            is: false,
            then: Yup.string().required('référence obligatoire'),
            otherwise: Yup.string()
        }),
        nameProduct: Yup.string().required('nom obligatoire'),
        selectedCategories: Yup.array().min(1,"catégorie obligatoire").required(),
        priceProduct: Yup.string().required('prix obligatoire'),
        discountPercentage: Yup.string().required('prix solde obligatoire'),
        description: Yup.string().required('description obligatoire'),
        quantityStock: Yup.number()
                        .integer('sans virgule')
                        .required('quantit obligatoire'),
        minOrderQuantity: Yup.number()
                        .integer('sans virgule')
                        .required('quantité minimal obligatoire'),
        active: Yup.bool(),
        hasVariant: Yup.bool(),
    })

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    } 


  return (
      <>
        <Toast ref={toast} />
      <div 
      className='flex flex-column sm:flex-row align-items-center justify-content-between' >
        <BreadCrump breadCrumpItems={breadCrumpItems} />
      </div>

      <div className='p-fluid grid'>
        <Formik 
        enableReinitialize={true}
        initialValues={initialValues} 
        validationSchema={validationSchema}
        onSubmit={onSubmit} >
        {({ handleChange, 
            handleSubmit,
            isSubmitting,
            handleBlur,
            values, 
            errors, 
            touched, 
            setFieldValue 
        }) =>
        {
            const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
            const getFormErrorMessage = (name) => {
                return isFormFieldValid(name) && <small className="p-error mt-2">{errors[name]}</small>;
            };

            return(
            <>
            <div className='col-12 md:col-7'>
            <div className='card'>

            {/* SKU BLOCK */}
            {!values.hasVariant
            &&<CustomInput
            label='référence de produit'
            placeholder='sku'
            handleChange={handleChange}
            value={values.reference}
            name='reference'
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
            />} 

            {/* NAME BLOCK */}
            <CustomInput
            label='titre de produit'
            placeholder='titre'
            handleChange={handleChange}
            value={values.nameProduct}
            name='nameProduct'
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}
            />

            {/* DESCRIPTION BLOCK */}
            <CustomTextArea
             label='description de produit'
             placeholder='description'
             handleChange={handleChange}
             value={values.description}
             name='description'
             isFormFieldValid={isFormFieldValid}
             getFormErrorMessage={getFormErrorMessage}
            />

            </div>

            {/* TAGS BLOCK */}
            <TagsBlock tags={values.tags} setFieldValue={setFieldValue} />

            <div className='card'>
                <p className='font-bold mb-2' >Média</p>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        ref={(el) => fileUploadRef = el} 
                        multiple
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        customUpload
                        uploadHandler={(e) => myUploader(values,e)}
                        onSelect={onTemplateSelect}
                        onError={onTemplateClear} onClear={onTemplateClear} onRemove={onTemplateRemove}
                        emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} />
            </div>  

            {/* OPTIONS BLOCK */}
            <OptionsBlock 
            hasVariant={values.hasVariant} 
            handleChange={handleChange}
            variants={variants} 
            setVariants={setVariants} 
            options={options} 
            setOptions={setOptions} />

            </div>
            
            <div className='col-12 md:col-5'>
                
            {/* CATEGORY BLOCK */}
            <CategoryBlock
            toast={toast}
            marginBottom={marginBottom}
            selectedCategories={values.selectedCategories}
            selectedUnderCategories={values.selectedUnderCategories}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
            isFormFieldValid={isFormFieldValid}
            getFormErrorMessage={getFormErrorMessage}/>

            {/* STOCK BLOCK */}
            {!values.hasVariant
            &&<StockBlock
                values={values}
                handleChange={handleChange}
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
            />}
                
            {/* PRICE BLOCK */}
           {!values.hasVariant
            &&<PriceBlock 
           values={values}
           handleChange={handleChange}
           isFormFieldValid={isFormFieldValid}
           getFormErrorMessage={getFormErrorMessage} 
           />}
                      
            {/* STATUS BLOCK */} 
            <div className="card">   
            <CustomSwitch
                label='status de produit'
                name='active'
                active={values.active} 
                handleChange={handleChange}/>
            </div>  
                                    
            </div>  

            <div
            className='mb-3 flex justify-content-end w-full'>
            <Button 
                label='sauvegarder'
                loading={loading}
                onClick={handleSubmit} 
                className='w-auto p-button-success' 
                icon="pi pi-check"/>
            </div>
            </>
            )
        }}
        </Formik>
      </div>
    </>
  )
}

export default AddProduct