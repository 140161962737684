import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { Toast } from 'primereact/toast';
import CustomInput from '../CustomInput';
import { BrandsService } from '../../service/BrandsService';

const RemiseModal = ({remiseDialog, setRemiseDialog, selectedUnderCategory}) => {

    const brandService = new BrandsService()
    const toast = useRef(null);
    const [loadingSubmit, setLoadingSubmit] = useState(false)
    
    //const headerTitle= 
    const initialValues = {
        remise: 0,
    }

    const validationSchema = Yup.object().shape({
        remise: Yup.number().required('remise obligatoire'),
    })
    
    const onSubmit = async (values,actions) => {
        setLoadingSubmit(true)
        const response = await brandService.applyRemiseByUnderCategory(selectedUnderCategory._id, values.remise)
        if(response.data){
            toast.current.show({severity:'success', summary: 'Success', detail:'remise appliquée avec succès', life: 3000});
            setRemiseDialog(false)
        } else {
            toast.current.show({severity:'error', summary: 'Error', detail:'erreur essayer plus tard', life: 3000});
        }
        setLoadingSubmit(false)
        
    }
    
    return(
        <>
        <Toast ref={toast} />
        {/* MODAL */}
        <Dialog draggable={false} visible={remiseDialog} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header={`Appliquer une remise pour la marque: ${selectedUnderCategory.nameUnderCategory}`} modal 
            className="p-fluid" onHide={() => setRemiseDialog(false)}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                {/* POURCENTAGE REMISE */}
                <CustomInput
                    keyfilter='pnum'
                    label='remise'
                    placeholder='pourcentage'
                    handleChange={handleChange}
                    value={values.name}
                    name='remise'
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                />


            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={() =>  setRemiseDialog(false)}/>
                <Button onClick={handleSubmit} 
                label={loadingSubmit?'loading':'sauvegarder'}
                disabled={loadingSubmit}
                loading={loadingSubmit}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
        </Dialog>
        </>
    )
}

export default RemiseModal