import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "primereact/button";
import { Formik } from "formik";
import { Toast } from "primereact/toast";
import { v4 as uuidv4 } from "uuid";
import {
  validationSchemaWithoutVariants,
  validationSchemaWithVariants,
} from "../../components/products/Edit_product/YupValidation";

// SERVICES ------------------------------------------------
import { BrandsService } from "../../service/BrandsService";
import { ImageService } from "../../service/ImageService";
import { ProductService } from "../../service/ProductService";
import { OptionsService } from "../../service/OptionsService";
import { VariantService } from "../../service/VariantService";

// BLOCKS ---------------------------------
import BreadCrump from "../../components/BreadCrump";
import CustomSwitch from "../../components/CustomSwitch";
import StockBlock from "../../components/products/Add_product/StockBlock";
import CategoryBlock from "../../components/products/Add_product/CategoryBlock";
import PriceBlock from "../../components/products/Add_product/PriceBlock";
import OptionsBlock from "../../components/products/Edit_product/OptionsBlock";
import TagsBlock from "../../components/products/Edit_product/TagsBlock";
import ImageTemplate from "../../components/products/Edit_product/ImageTemplate";
import ImageUpload from "../../components/products/Edit_product/ImageUpload";
import { LoadingComponent } from "../../components/LoadingComponents";
import CustomInput from "../../components/CustomInput";
import CustomTextArea from "../../components/CustomTextArea";

const EditProduct = () => {
  const variantService = new VariantService();
  const productService = new ProductService();
  const optionsService = new OptionsService();
  const imageService = new ImageService();

  const marginBottom = 25;
  const [options, setOptions] = useState([]);
  const [productInfo, setProductInfo] = useState({});
  const [productInfoLoading, setProductInfoLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [optionsLoading, setOptionsLoading] = useState(true);
  const [deleteVariantsId, setDeletedVariantsId] = useState([]);
  const [deleteOptionsId, setDeletedOptionId] = useState([]);
  const [variants, setVariants] = useState([]);
  const [newTags, setNewTags] = useState([]);
  const [generatedVariants, setGeneratedVariants] = useState([]);
  const [singleVariant, setSingleVariant] = useState({});
  const toast = React.useRef(null);
  let fileUploadRef = useRef(null);
  const navigate = useNavigate();
  let { _id } = useParams();

  const getProductDetail = async () => {
    const response = await productService.getProductDetail(_id);
    if (response.data) {
      response.data.category = response.data.category.map(({ _id }) => _id);
      if (response.data.underCategory != null)
        response.data.underCategory = response.data.underCategory.map(
          ({ _id }) => _id
        );
      setProductInfo(response.data);
      setProductInfoLoading(false);
      if (response.data.hasVariant) {
        getOptions();
      } else {
        getSingleVariant();
      }
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "erreur de serveur, réessayez plus tard",
        life: 3000,
      });
      console.log(response.error);
    }
  };

  // IF SIMPLE PRODUCT GET SKU
  const getSingleVariant = async () => {
    const response = await variantService.getVariantsByProduct(_id);
    if (response.data) {
      setSingleVariant(response.data[0]);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "erreur de serveur, réessayez plus tard",
        life: 3000,
      });
      console.log(response.error);
    }
  };

  // GET OPTIONS
  const getOptions = async () => {
    const response = await optionsService.getOptions(_id);
    if (response.data) {
      setOptions(response.data);
      setOptionsLoading(false);
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "erreur de serveur, réessayez plus tard",
        life: 3000,
      });
      console.log(response.error);
      setOptionsLoading(false);
    }
  };

  useEffect(() => {
    getProductDetail();
  }, []);

  const initialValuesWithVariants = {
    _id: _id,
    nameProduct: productInfo?.nameProduct,
    selectedCategories: productInfo?.category,
    selectedUnderCategories: productInfo?.underCategory,
    photos: productInfo?.photos,
    description: productInfo?.description,
    active: productInfo?.active,
    tags: productInfo?.tags,
    numberImages: 0,
    deletedImages: [],
    hasVariant: productInfo?.hasVariant,
  };

  const initialValuesWithoutVariants = {
    reference: singleVariant?.reference,
    nameProduct: productInfo?.nameProduct,
    selectedCategories: productInfo?.category,
    selectedUnderCategories: productInfo?.underCategory,
    priceProduct: singleVariant.priceProduct,
    discountPercentage: singleVariant?.discountPercentage,
    photos: productInfo?.photos,
    tags: productInfo?.tags,
    description: productInfo?.description,
    quantityStock: singleVariant?.quantityStock,
    minOrderQuantity: singleVariant?.minOrderQuantity,
    active: productInfo?.active,
    numberImages: 0,
    deletedImages: [],
    hasVariant: productInfo?.hasVariant,
  };

  //-----------handle template images-------------//
  //when the selected images uploaded
  const myUploader = async (values, event) => {
    let firebaseUrl = [];
    const files = event.files;
    for (let i = 0; i < files.length; i++) {
      const blob = await fetch(files[i].objectURL).then((r) => r.blob()); //get blob url
      const url_product = await imageService.uploadImage(
        blob,
        `products/${files[i].name}${uuidv4()}`
      ); // upload to firebase and get url
      firebaseUrl.push(url_product.data);
    }
    values.photos =
      values.photos.length > 0
        ? values.photos.concat(firebaseUrl)
        : firebaseUrl;
    const {
      nameProduct,
      selectedCategories,
      selectedUnderCategories,
      photos,
      description,
      active,
    } = values;
    const data = {
      nameProduct,
      category: selectedCategories,
      underCategory: selectedUnderCategories,
      photos,
      description,
      tags: productInfo?.tags,
      active,
    };
    await _editProduct(_id, data);
  };

  // PUSH OPTION ID TO EACH VARIANT
  const pushIdToGeneratedVariants = (responseOpt, generatedVariants) => {
    let opt1, opt2, opt3;
    let newArr = [...generatedVariants];

    newArr.map((_var, index) => {
      if (_var.option1) {
        if (typeof opt1 === "undefined")
          opt1 = responseOpt.find((op) => op.numOption === 1);
        newArr[index].option1.optionId = opt1._id;
      }
      if (_var.option2) {
        if (typeof opt2 === "undefined")
          opt2 = responseOpt.find((op) => op.numOption === 2);
        newArr[index].option2.optionId = opt2._id;
      }
      if (_var.option3) {
        if (typeof opt3 === "undefined")
          opt3 = responseOpt.find((op) => op.numOption === 3);
        newArr[index].option3.optionId = opt3._id;
      }
    });
    return newArr;
  };

  // ADD VARIANT TO DB
  async function addVariant(variant) {
    const variantService = new VariantService();
    const response = await variantService.addVariant(variant);
    if (response.data) {
      console.log("variant added");
    } else {
      console.log(response.error);
      console.log("error add variant");
    }

    return response;
  }

  // REF FOR IMAGE UPLOAD
  const assignRef = (el) => {
    fileUploadRef = el;
  };

  // SUBMIT EDIT
  async function onSubmit(values, actions) {
    await _deleteImages(values.deletedImages);
    !productInfo?.hasVariant && (await _editVariant(values));

    if (values.numberImages > 0) {
      fileUploadRef.upload();
    } else {
      values.category = values.selectedCategories;
      values.underCategory = values.selectedUnderCategories;
      delete values.selectedCategories;
      delete values.selectedUnderCategories;
      await _editProduct(_id, values);
    }

    if (productInfo?.hasVariant) {
      setLoading(true);

      if (generatedVariants.length > 0) {
        const _generatedVariants = variants.filter((vt) => vt.new === true);
        const _oldVariants = variants.filter((vt) => !vt.new);

        for (let i in _generatedVariants) {
          _generatedVariants[i].product = _id;
          delete _generatedVariants[i]._id;
          delete _generatedVariants[i].new;
        }

        const arr = pushIdToGeneratedVariants(options, _generatedVariants);

        const varRes = await addVariant(arr);
        if (varRes.data) {
          console.log("new generated variant are added");
        } else {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "erreur de serveur, réessayez plus tard",
            life: 3000,
          });
        }

        for (let i in _oldVariants) {
          const res = await variantService.editVariant(
            _oldVariants[i]._id,
            _oldVariants[i]
          );
          if (res.data) {
            setLoading(false);
            console.log("edit old variant");
          } else {
            console.log(res.error);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "erreur update old variant essayer plus tard",
              life: 3000,
            });
          }
        }
      } else {
        console.log("variants", variants);
        for (let i in variants) {
          const res = await variantService.editVariant(
            variants[i]._id,
            variants[i]
          );
          if (res.data) {
            setLoading(false);
          } else {
            console.log(res.error);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "erreur de serveur, réessayez plus tard",
              life: 3000,
            });
          }
        }
      }

      for (let i in options) {
        const res = await optionsService.updateOption(
          options[i]._id,
          options[i]
        );
        if (res.data) {
          console.log("edit option");
        } else {
          console.log(res.error);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "erreur de serveur, réessayez plus tard",
            life: 3000,
          });
        }
      }

      if (deleteVariantsId.length > 0) {
        for (let i in deleteVariantsId) {
          await variantService.deleteVariant(deleteVariantsId[i]);
        }
      }
    }
  }

  // EDIT PRODUCT
  async function _editProduct(_id, data) {
    setLoading(true);
    const _data = { ...data, tags: [...data.tags, ...newTags] };
    const response = await productService.updatedProduct(_id, _data);
    if (response.data) {
      setLoading(false);
      navigate("/products");
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "erreur de serveur, réessayez plus tard",
        life: 3000,
      });
      console.log(response.error);
    }
  }

  // EDIT VARIANT BY ID
  async function _editVariant(variantData) {
    const {
      reference,
      priceProduct,
      discountPercentage,
      quantityStock,
      minOrderQuantity,
    } = variantData;

    const _variantData = {
      reference,
      priceProduct,
      discountPercentage,
      quantityStock,
      minOrderQuantity,
    };
    await variantService.editVariant(singleVariant._id, _variantData);
  }

  // DELETE IMAGES
  async function _deleteImages(urls) {
    if (urls.length > 0) {
      for (let i = 0; i < urls.length; i++) {
        await imageService.deletImage(urls[i]);
      }
    }
  }

  const breadCrumpItems = {
    href: "products",
    parentName: "produits",
    currentPage: initialValuesWithoutVariants.nameProduct,
  };

  if (productInfoLoading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column sm:flex-row align-items-center justify-content-between">
        <BreadCrump breadCrumpItems={breadCrumpItems} />
      </div>

      <div className="p-fluid grid">
        <Formik
          enableReinitialize={true}
          initialValues={
            productInfo?.hasVariant
              ? initialValuesWithVariants
              : initialValuesWithoutVariants
          }
          validationSchema={
            productInfo?.hasVariant
              ? validationSchemaWithVariants
              : validationSchemaWithoutVariants
          }
          onSubmit={onSubmit}
        >
          {({
            handleChange,
            handleSubmit,
            isSubmitting,
            handleBlur,
            values,
            errors,
            touched,
            setFieldValue,
          }) => {
            const isFormFieldValid = (name) =>
              !!(touched[name] && errors[name]);
            const getFormErrorMessage = (name) => {
              return (
                isFormFieldValid(name) && (
                  <small className="p-error mt-2">{errors[name]}</small>
                )
              );
            };

            return (
              <>
                <div className="col-12 md:col-7">
                  <div className="card">
                    {/* SKU */}
                    {!productInfo?.hasVariant && (
                      <CustomInput
                        label="référence de produit"
                        placeholder="sku"
                        handleChange={handleChange}
                        value={values.reference}
                        name="reference"
                        isFormFieldValid={isFormFieldValid}
                        getFormErrorMessage={getFormErrorMessage}
                      />
                    )}

                    {/* NAME */}
                    <CustomInput
                      label="titre de produit"
                      placeholder="titre"
                      handleChange={handleChange}
                      value={values.nameProduct}
                      name="nameProduct"
                      isFormFieldValid={isFormFieldValid}
                      getFormErrorMessage={getFormErrorMessage}
                    />

                    {/* DESCRIPTION */}
                    <CustomTextArea
                      label="description de produit"
                      placeholder="description"
                      handleChange={handleChange}
                      value={values.description}
                      name="description"
                      isFormFieldValid={isFormFieldValid}
                      getFormErrorMessage={getFormErrorMessage}
                    />
                  </div>

                  <TagsBlock
                    tags={values.tags}
                    setFieldValue={setFieldValue}
                    newTags={newTags}
                    setNewTags={setNewTags}
                  />

                  {values?.photos.length > 0 && (
                    <ImageTemplate
                      setFieldValue={setFieldValue}
                      deletedImages={values?.deletedImages}
                      images={values?.photos}
                    />
                  )}

                  {/* template upload images */}
                  <ImageUpload
                    assignRef={assignRef}
                    setFieldValue={setFieldValue}
                    values={values}
                    myUploader={myUploader}
                  />

                  {productInfo?.hasVariant && (
                    <OptionsBlock
                      optionsLoading={optionsLoading}
                      setGeneratedVariants={setGeneratedVariants}
                      generatedVariants={generatedVariants}
                      deleteOptionsId={deleteOptionsId}
                      setDeletedOptionId={setDeletedOptionId}
                      setDeletedVariantsId={setDeletedVariantsId}
                      deleteVariantsId={deleteVariantsId}
                      toast={toast}
                      productId={_id}
                      hasVariant={productInfo?.hasVariant}
                      variants={variants}
                      setVariants={setVariants}
                      options={options}
                      setOptions={setOptions}
                    />
                  )}
                </div>

                <div className="col-12 md:col-5">
                  <CategoryBlock
                    toast={toast}
                    marginBottom={marginBottom}
                    selectedCategories={values.selectedCategories}
                    selectedUnderCategories={values.selectedUnderCategories}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    isFormFieldValid={isFormFieldValid}
                    getFormErrorMessage={getFormErrorMessage}
                  />

                  {!productInfo?.hasVariant && (
                    <StockBlock
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isFormFieldValid={isFormFieldValid}
                      getFormErrorMessage={getFormErrorMessage}
                    />
                  )}

                  {!productInfo?.hasVariant && (
                    <PriceBlock
                      values={values}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      isFormFieldValid={isFormFieldValid}
                      getFormErrorMessage={getFormErrorMessage}
                    />
                  )}

                  {/* STATUS  */}
                  <div className="card">
                    <CustomSwitch
                      label="status de produit"
                      name="active"
                      active={values.active}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                <div className="mb-3 flex justify-content-end w-full">
                  <Button
                    label="modifier"
                    type="submit"
                    onClick={handleSubmit}
                    loading={loading}
                    disabled={loading}
                    className="w-auto p-button-warning"
                    icon="pi pi-pencil"
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default EditProduct;
