import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Toolbar } from 'primereact/toolbar';
import { InputText } from 'primereact/inputtext';
import { CategoryService } from '../service/CategoryService';
import { InputSwitch } from 'primereact/inputswitch';
import AddCategory from '../components/Categories/AddCategory';
import EditCategory from '../components/Categories/EditCategory';
import ImageTemplate from '../components/orders/ImageTemplate';
import NoData from '../components/NoData';



const Categories = () => {
    const categoryService = new CategoryService();
    const [categories, setCategories] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);

    useEffect(() => {
        getData()
    }, []);

    async function getData(){
        const response = await categoryService.getCategories()
        if(response.data){
            setCategories(response.data)
            setLoading(false)
        } else {
            console.log(response.error);
            setLoading(false)
        }
    }

    const updateStatusCategoty = async(_id,active) => {
        const response = await categoryService.updateCategory(_id,{active})
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const createCategory = async (nameCateogry,url) => {
        const response = await categoryService.createCategory(nameCateogry,url)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }

    const updateCtagory=async(_id,values)=>{
       
        const response = await categoryService.updateCategory(_id,values)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }


    const imageTemplate = (rowData) => {
        return (
            <>
            {
                rowData.photo.url !== null
                ? <img src={rowData.photo.url} alt={rowData.photo.url} className="shadow-2" width="50" />
                : <b style={{color:'#f00'}}>aucune image</b>
            }
            </>   
        )
    }


    const statusBodyTemplate = (rowData) => {
        return (
            <InputSwitch checked={rowData.active} onChange={(e) => 
                updateStatusCategoty(rowData._id,e.value)
            } />
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <EditCategory updateCtagory={updateCtagory} rowData={rowData} />
            </div>
        );
    }



    const onRowReorder = async(e) => {
        setLoading(true)
        const response = await categoryService.reorderCategory(e.value);
        setCategories(e.value);
        setLoading(false)
        //toast.current.show({severity:'success', summary: 'Rows Reordered', life: 3000});
    }


  return (
    <div className="grid crud-demo">
            <div className="col-12">
                <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Catégorie</p>
                    </div>
                    <AddCategory createCategory={createCategory}/>
                </div>

                <div className='card'>
                    <DataTable size='small'
                    reorderableRows onRowReorder={onRowReorder}
                    loading={loading}
                    stripedRows 
                    ref={dt} 
                    value={categories} 
                    responsiveLayout="scroll"
                    rowHover 
                    selection={selectedCategory} 
                    onSelectionChange={(e) => setSelectedCategory(e.value)}
                    globalFilter={globalFilter} 
                    dataKey="id" 
                    rows={10} 
                    rowsPerPageOptions={[5, 10, 25]} 
                    className="datatable-responsive"
                    emptyMessage={<NoData label='Aucune catégorie disponible' />}>
                            <Column rowReorder style={{width: '3em'}} />
                            <Column sortable field="nameCategory" header="Name"></Column>
                            <Column field="photo" header="image" body={(rowData)=><ImageTemplate photos={[rowData.photo.url]}/>}></Column>
                            <Column sortable field="active" header="status" body={statusBodyTemplate}></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>
                </div>
            </div>
        </div>
  )
}


export default React.memo(Categories);

