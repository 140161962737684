import Api from "../utils/Api";

export class ProposalsService {


    // GET ALL PROPOSALS
    async getPropsals(lazyParams, totalRecords){
        const {first, rows, page} = lazyParams
        let response = {}
        let parameters = `totalSkip=${first}&currentPage=${page}&totalDocuments=${totalRecords}`
        try {
            const proposals = await Api.get(`suggestion?${parameters}`)
            response.data = proposals.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    // GET UNREAD PROPOSALS
    async getUnreadProposals(){
        let response = {}
        let parameters = `read=false`
        try {
            const res = await Api.get(`suggestion?${parameters}`)
            response.data = res.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

    // UPDATE READ STATUS
    async updateStatus(id, data){
        let response = {}
        try {
            const res = await Api.put(`suggestion/${id}/update-read-status`,data)
            response.data = res.data
            return response
        } catch (error) {
            response.error = error
            return response
        }
    }

}
