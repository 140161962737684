import React, { useState} from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import CustomInput from '../CustomInput';

const EditAdmin = ({ setToggleMenu,toggleMenu,updateAdmin, rowData }) => {

    const [dialogVisibility, setDialogVisibility] = useState(false);
    const hideDialog = () => {
        setDialogVisibility(false)
        setToggleMenu(!toggleMenu)
    }
    const openNew = () => setDialogVisibility(true)

    const initialValues = {
        name: rowData.name,
        email: rowData.email,
    }
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('nom de livreur obligatoire'),
        email: Yup.string().email("saisir un email valide").required("numero de telephone obligatoire"),

    })

    const onSubmit = async (values,actions) => {
        await updateAdmin(rowData._id, values)
        hideDialog()
        setToggleMenu(!toggleMenu)
    }
    
    
    return(
        <>
        <div 
            className='align-items-center flex p-2 pl-3 pr-6 menu-child'
            onClick={openNew}
            >
            <i className='pi pi-pencil'></i>
            <span className='uppercase ml-2'>modifier</span>
        </div>
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Modifier Admin" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                {/* name admin */}
                <CustomInput
                label='nom et prénom'
                placeholder='nom et prénom'
                handleChange={handleChange}
                value={values.name}
                name='name'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                />

                {/* email */}
                <CustomInput
                label='émail'
                placeholder='émail'
                handleChange={handleChange}
                value={values.email}
                name='email'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                />

            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'modifier'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-warning' 
                icon="pi pi-pencil"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
            </Dialog>
        </>
    )
}

export default EditAdmin