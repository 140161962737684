import Api from "../utils/Api";

export class CategoryService {

  // GET ALL CATEGORIES
  async getCategories() {
    let response  = {}
    try {
      const categories = await Api.get('category/getAll')
      response.data = categories.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  // UPDATE CATEGORY
  async updateCategory (_id,data) {
    let response = {}
    try {
      const updatedCategory = await Api.put(`category/update/${_id}`,data)
      response.data = updatedCategory.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  // DELETE CATEGORY
  async deleteCategory (_id){
    let response = {}
    try {
      const deleteCategory = await Api.delete(`category/delete/${_id}`)
      response.data = deleteCategory.data.message
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  // CREATE CATEGORY
  async createCategory (data){
    let response = {}
    try {
      const createdCategory = await Api.post(`category/create`,data)
      response.data = createdCategory.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async reorderCategory (categories){
    if(categories){
      let totalCategories = categories.length
      let categoriesPriority = []
      //reorder categories
      for(let i = 0; i < categories.length; i++){
        categoriesPriority.push({_id: categories[i]._id, priority: totalCategories})
        totalCategories -= 1
      }
      let response = {}
      try {
        await Api.put(`category/reorder`,{categoriesPriority})
        response.data = "reorder successfully"
        return response
      } catch (error) {
        response.error = error
        return response
      }
    }
  }

  displayCategories = (categories) => {
    let _categories = ``
    categories.map(({ nameCategory }, index) => {
        if((index + 1) == categories.length)  _categories+= nameCategory
        else _categories+= `${nameCategory} - `
    })
    return _categories
  }

  displayUnderCategories = (underCategories) => {
    let _underCategories = ``
    underCategories.map(({ nameUnderCategory }, index) => {
        if((index + 1) == underCategories.length)  _underCategories+= nameUnderCategory
        else _underCategories+= `${nameUnderCategory} - `
    })
    return _underCategories
  }


}