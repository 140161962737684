import { Dialog } from 'primereact/dialog';
import React, { useState } from 'react'

const SuggestionImageModal = ({photo}) => {


    const [dialogVisibility, setDialogVisibility] = useState(false); 
    const hideDialog = () => setDialogVisibility(false)
    const openModal = () => setDialogVisibility(true)


  return (
    <> 
    <img onClick={openModal} className='m-1 cursor-pointer' key={photo} src={photo} height='130px' width='130px' />
    
    {/* MODAL */}
    <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
        modal className="p-fluid" onHide={hideDialog}>

          <div className='w-full h-screen'>
            <img style={{objectFit:'contain'}} className='w-full h-full' src={photo} alt="img" srcset="" />
          </div>

        </Dialog>
    
    
    </>
  )
}

export default SuggestionImageModal