import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { FileUpload } from 'primereact/fileupload';
import { ImageService } from '../../service/ImageService';
import { v4 as uuidv4 } from 'uuid';
import { Dropdown } from 'primereact/dropdown';
import { CategoryService } from '../../service/CategoryService';
import { BrandsService } from '../../service/BrandsService';
import { ProductService } from '../../service/ProductService';
import { MultiSelect } from 'primereact/multiselect';

const AddOffer = ({ createOffer }) => {
    
    const categoryService = new CategoryService()
    const underCategoryService = new BrandsService()
    const productService = new ProductService()
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [underCategories, setUnderCategories] = useState([])
    const [selectedImage, setSelectedImage] = useState(null)
    const [selectedProduct, setSelectedProduct] = useState([])
    const [loadingCategories, setLoadingCategories] = useState(false)
    const [loadingUnderCategories, setLoadingUnderCategories] = useState(false)
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)


    const initialValues = {
        nameOffer: '',
        photo: '',
        category: '',
        underCategory : '',
        product: [],
        active: false
    }
    
    const validationSchema = Yup.object().shape({
        nameOffer: Yup.string().required("le nom de l'offre est obligatoire"),
        photo: Yup.string().required("image obligatoire"),
        category: Yup.string().optional(),
        underCategory: Yup.string().optional(),
        product:  Yup.array().max(3, 'Array should have a maximum length of 3'),
        active: Yup.bool(),
    })

    useEffect(() => {
        if(dialogVisibility){
            getCategories()
            getUnderCategories()
        }

        return () => {
            setSelectedImage(null)
        }
    }, [dialogVisibility])

    const getCategories = async () => {
        setLoadingCategories(true)
        const response = await categoryService.getCategories()
        if(response.data){
            setCategories(response.data)
        } else {
            console.log(response.error)
        }
        setLoadingCategories(false)
    }

    const getUnderCategories = async () => {
        setLoadingUnderCategories(true)
        const response = await underCategoryService.getSubCategories()
        if(response.data){
            setUnderCategories(response.data)
        } else {
            console.log(response.error)
        }
        setLoadingUnderCategories(false)
    }
    const onSearchProducts = async (e) => {
        const value = e.filter
        if(value != ""){
          const lazyParams = {
            first: 0, 
            page:1, 
            filters : {
              nameProduct: value
            }, 
            sortfield: null, 
            sortorder: -1
          }
          const response = await productService.getProducts(lazyParams, 0)
          let _products = []
          if(response.data && response.data.products.length > 0){
            response.data.products.map((product) => {
              _products.push({ 
                _id: product._id,
                nameProduct: product.nameProduct,
              })
            })
            setProducts([..._products])
          }
        } else {
            setProducts([])
        }
    
    }

    console.log("Selected product: ",selectedProduct)

    const getProductDetailFromSearchProducts = (productId) => {
        const product = products.find(p => p._id === productId)
        return product

    }
    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {
                 selectedImage == null && chooseButton
                }
                {cancelButton}
            </div>
        )
    } 


    //when an image added
    const onTemplateSelect = (e, setFieldValue) => {
        const objectURL = e.files[0]?.objectURL
        setSelectedImage(objectURL)
        setFieldValue('photo',objectURL)
    }

    //when an image removed
    const onTemplateRemove = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    //when all images removed
    const onTemplateClear = (setFieldValue) => {
        setSelectedImage(null)
        setFieldValue('photo', '')
    }

    const onSubmit = async (values, actions) => {
        const { nameOffer, photo, category, underCategory, product, active} = values
        const imageService = new ImageService()
        const blob = await fetch(photo).then(r => r.blob()); //get blob url
        const url_image = await imageService.uploadImage(blob,`offers/${nameOffer}${uuidv4()}`) // upload to firebase and get url
        let data = {
            nameOffer, 
            photo: url_image.data, 
            active
        }
        if(category ) data["category"] = category
        if(underCategory) data["underCategory"] = underCategory
        if(product.length > 0) data["product"] = product[0]
        await createOffer(data)
        setSelectedImage(null)
        hideDialog()
    }
    
    return(
        <>
        <Button 
        label="ajouter" 
        icon="pi pi-plus" 
        className="mr-2" 
        onClick={openNew} />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Ajouter nouvelle offre" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
                console.log(values.product)
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                {/* offer name */}
                <div className='mb-3'>
                    <p className="mb-2">nom de l'offre</p>
                    <div className="field flex flex-column">
                    <InputText 
                    onChange={handleChange('nameOffer')} 
                    placeholder="nom de l'offre"
                    onBlur={handleBlur('nameOffer')}
                    className={classNames({ 'p-invalid':  isFormFieldValid('nameOffer')})} />
                    {getFormErrorMessage('nameOffer')}
                </div>

                {/* CATEGORY */}
                <div className='mb-3'>
                    <p className="mb-2">Catégories</p>  
                <div className="flex align-items-center">
                    <Dropdown
                        disabled={
                            loadingCategories || 
                            (values.underCategory != "" && true) ||
                            (values.product.length > 0 && true)
                        }
                        filter
                        value={values.category}
                        placeholder={loadingCategories ? "chargement" : 'Choisir une catégorie'}
                        options={categories} 
                        onChange={handleChange("category")}
                        optionLabel='nameCategory'
                        optionValue='_id'
                        className={classNames({ 'p-invalid':  isFormFieldValid('category')})}
                    />
                    <Button
                        onClick={() => setFieldValue("category", '')}
                        icon="pi pi-trash"
                        className="ml-2 p-button-danger"
                        aria-label="filter"
                    />
                </div>
                </div>
                {/* UNDERCATEGORY */}
                <div className='mb-3'>
                    <p className="mb-2">Sous-catégorie / marque</p>   
                <div className="flex align-items-center">
                    <Dropdown 
                        disabled={
                            loadingUnderCategories ||
                            (values.category != "" && true) ||
                            (values.product.length > 0 && true)
                        }
                        filter
                        value={values.underCategory}
                        placeholder={loadingUnderCategories ? 'chargement' : 'Choisir une sous-catégorie / marque'}
                        options={underCategories} 
                        optionLabel='nameUnderCategory'
                        optionValue='_id'
                        onChange={handleChange("underCategory")}
                        className={classNames({ 'p-invalid':  isFormFieldValid('underCategory')})}
                    />
                    <Button
                        onClick={() => setFieldValue("underCategory", '')}
                        icon="pi pi-trash"
                        className="ml-2 p-button-danger"
                        aria-label="filter"
                    />
                </div>
                </div>

                {/* PRODUCT */}
                <div className='mb-3'>
                    <p className="mb-2">Produits</p>   
                <div className="flex align-items-center">
                    <MultiSelect 
                        filter
                        disabled={
                            (values.category != "" && true) ||
                            (values.underCategory != "" && true)
                        }
                        value={values.product}
                        placeholder='Choisir un produit'
                        selectionLimit={1}
                        options={products.length > 0 ? products : selectedProduct}
                        optionLabel='nameProduct'
                        optionValue='_id'
                        onFilter={onSearchProducts}
                        onChange={(e) => {
                            handleChange("product")(e)
                            if(e.target.value.length > 0) {
                                const _selectedP = getProductDetailFromSearchProducts(e.target.value[0])
                                setSelectedProduct([{ ..._selectedP }])
                                
                            } else {
                                setFieldValue("product",[])

                            }
                        }
                        }
                        className={classNames({ 'p-invalid':  isFormFieldValid('product')})}
                    />
                    <Button
                        onClick={() => setFieldValue("product", null)}
                        icon="pi pi-trash"
                        className="ml-2 p-button-danger"
                        aria-label="filter"
                    />                    
                </div>
                </div>                

               
                {/* STATUS */}
                <div className='mb-3'>
                    <p className="mb-2">active</p>
                    <InputSwitch checked={values.active}
                    onChange={handleChange('active')} />
                    {getFormErrorMessage('active')}
                </div>
    
                {/*template image */}      
                <div>
                    <h5>Images</h5>
                    <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
                        multiple={false}
                        headerTemplate={headerTemplate}
                        accept="image/*" 
                        maxFileSize={2000000}
                        onSelect={(e) => onTemplateSelect(e,setFieldValue)}
                        onError={(e) => onTemplateClear(setFieldValue)} 
                        onClear={(e) =>onTemplateClear(setFieldValue)} 
                        onRemove={(e) => onTemplateRemove(setFieldValue)}
                        emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} 
                        />
                        { getFormErrorMessage('photo')}

                </div>          
                
            </div>
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'sauvegarder'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            </Dialog>
        </>
    )
}

export default AddOffer