import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import NoData from '../components/NoData';
import { TypesService } from '../service/TypesService';
import { InputSwitch } from 'primereact/inputswitch';
import AddType from '../components/types/AddType';
import EditType from '../components/types/EditType';

const Types = () => {
    const typesService = new TypesService();
    const [loading, setLoading] = useState(true);
    const [types, setTypes] = useState(null);
    const [selectedTypes, setSelectedType] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        getData()
    }, []);

    async function getData() {
        const response = await typesService.getCustomersTypes();
        if(response.data){
            setTypes(response.data) 
            setLoading(false)
        }else{
            console.log(response.error) 
            setLoading(false)
        }
    }


    // UPDATE CUSTOMER TYPES
    const updateType = async(_id,data) => {
        const response = await typesService.updateType(_id,data)
        if(response.data){
            getData()
            
        } else {
            console.log(response.error);
        }
    }

    // UPDATE TYPE STATUS
    const updateTypeStatus = async(_id,active) => {
        const response = await typesService.updateType(_id,{active})
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }


    // CREATE CUSTOMER TYPES
    const createType = async (customerType) => {
        const typesService = new TypesService();
        const response = await typesService.createType(customerType)
        if(response.data){
            getData()
        } else {
            console.log(response.error);
        }
    }



    const statusBodyTemplate = (rowData) => {
        return (
            <InputSwitch checked={rowData.active} 
            onChange={(e) => updateTypeStatus(rowData._id,e.value)} />
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <EditType updateType={updateType} rowData={rowData} />
            </div>
        );
    }


  return (
    <div className="grid crud-demo">
        <Toast ref={toast} />
            <div className="col-12">

            <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Types des utilisateurs</p>
                    </div>
                    <AddType createType={createType}/>
                </div>
                
                <div className="card">
                    <DataTable 
                    size='small' 
                    stripedRows ref={dt} 
                    value={types} 
                    responsiveLayout="scroll"
                    loading={loading} 
                    rowHover 
                    selection={selectedTypes} 
                    onSelectionChange={(e) => setSelectedType(e.value)}
                    globalFilter={globalFilter} 
                    dataKey="_id" 
                    rows={10} 
                    className="datatable-responsive"
                    emptyMessage={<NoData label='Aucune type disponible' />}>
                            <Column field="customerType" header="type"></Column>
                            <Column field="active" header="statut" body={statusBodyTemplate}></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>  
                </div>
            </div>
        </div>
  )
}

export default Types