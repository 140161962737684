import * as Yup from 'yup'

export const validationSchemaWithoutVariants = Yup.object().shape({
    reference: Yup.string().when('hasVariant', {
        is: false,
        then: Yup.string().required('référence obligatoire'),
        otherwise: Yup.string()
    }),
    nameProduct: Yup.string().required('nom obligatoire'),
    selectedCategories: Yup.array().min(1,"catégorie obligatoire").required(),
    priceProduct: Yup.string().required('prix obligatoire'),
    discountPercentage: Yup.string().required('pourcentage de remise'),
    description: Yup.string().required('description obligatoire'),
    quantityStock: Yup.number()
                    .integer('sans virgule')
                    .required('quantité obligatoire')
                    .test(
                        'Is positive?', 
                        'La quantité doit être supérieure ou égale 0 !', 
                        (value) => value >= 0
                    ),
    minOrderQuantity: Yup.number()
                    .integer('sans virgule')
                    .required('quantité minimal obligatoire')
                    .test(
                        'Is positive?', 
                        'La quantité doit être supérieure à 0!', 
                        (value) => value > 0
                    ),
    active: Yup.bool(),
    hasVariant: Yup.bool(),
})

export const validationSchemaWithVariants = Yup.object().shape({
  nameProduct: Yup.string().required('nom obligatoire'),
  description: Yup.string().required('description obligatoire'),
  selectedCategories: Yup.array().min(1,"catégorie obligatoire").required(),
})