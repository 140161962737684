import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { AdminService } from '../service/AdminService';
import AddAdmin from '../components/admin/AddAdmin'
import EditAdmin from '../components/admin/EditAdmin'
import EditPassword from '../components/admin/EditPassword'
import NoData from '../components/NoData';
import OptionsMenu from '../components/OptionsMenu';
import SingleDelete from '../components/SingleDelete'

const Admins = () => {

    const adminService = new AdminService()
    const [admins, setAdmins] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state

    const dt = useRef(null);

    async function getAdmins(){
        setLoading(true)
        const response = await adminService.getAllAdmins()
        if(response.data){
            console.log("ADMIN response", response.data)
            setAdmins(response.data)
        } else {
            console.log(response.error);
        }
        setLoading(false)

    }


    useEffect(() => {
        getAdmins()
    }, []);


    const createSousAdmin = async (data) => {
        const response = await adminService.createSousAdmin(data)
        if(response.data){
            getAdmins()
        } else {
            console.log(response.error);
        }
    }


    const updateAdmin = async (id,data) => {
        const response = await adminService.updateAdmin(id,data)
        if(response.data){
            getAdmins()
        } else {
            console.log(response.error);
        }
    }

    const updatePasswordAdmin = async (id, newPassword) => {
        const response = await adminService.updatePassword(id, newPassword)
        if(response.data){
            console.log(response.data);
        } else {
            console.log(response.error);
        }
    }

    const deleteAdmin=async(id)=>{
        const response = await adminService.deleteAdmin(id)
        if(response.data){
            getAdmins()
        } else {
            console.log(response.error);
        }
    }
    

    const actionBodyTemplate = (rowData) => {
        return (
            <OptionsMenu
                rowData={rowData} 
                setToggleMenu={setToggleMenu} 
                toggleMenu={toggleMenu}>
            {
            rowData._id === toggleMenu 
              && <div className='pb-2 pt-2 options-menu'>
                    <EditAdmin toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} updateAdmin={updateAdmin} rowData={rowData}/>
                    <EditPassword toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} updatePasswordAdmin = {updatePasswordAdmin} rowData={rowData} />
                    <SingleDelete id={rowData?._id} name={rowData?.name} toggleMenu={toggleMenu} deleteFunction={()=>deleteAdmin(rowData._id)} setToggleMenu={setToggleMenu}/>
              </div>
            }
          </OptionsMenu>
        );
    }

  return (
    <div className="grid crud-demo">
            <div className="col-12">
                <div>

                <div className='mb-4 flex alignItems-center justify-content-between'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Admins</p>
                    </div>
                    <AddAdmin createSousAdmin={createSousAdmin} />
                </div>
                
                <div className='card'>
                    <DataTable size='small' 
                    stripedRows 
                    ref={dt} 
                    value={admins} 
                    responsiveLayout="scroll"
                    loading={loading} 
                    rowHover
                    globalFilter={globalFilter} 
                    paginator
                    dataKey="id" 
                    rows={25} 
                    rowsPerPageOptions={[5, 10, 25, 50]} 
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Afficher {first} à {last} de {totalRecords} livreurs"
                    emptyMessage={<NoData label='Aucun admin disponible' />}>
                            <Column sortable field="name" header="Nom complet"></Column>
                            <Column sortable field="email" header="email"></Column>
                            <Column sortable field="type" header="type"></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>  
                </div>
            </div>
        </div>
  )
}

export default Admins