import { constSelector } from "recoil";
import Api from "../utils/Api";
import { NotificationService } from "./NotificationService";

export class OrderService {

  async getAllOrders(lazyParams, totalRecords){
    const {first, rows, page, filters : {date, status, customer,numOrder}, sortfield, sortorder } = lazyParams
    let response = {}
    const _sortfield = sortfield == null ? '_id' : sortfield
    let parameters = `totalSkip=${first}&currentPage=${page}&totalDocuments=${totalRecords}&sortfield=${_sortfield}&sortorder=${sortorder}`
    if(date != null) parameters += `&date=${date.toISOString()}`
    if(status != null) parameters += `&status=${status}`
    if(customer != null) parameters += `&status=${customer}`
    if(numOrder != null) parameters += `&numOrder=${numOrder}`
    try {
      const orders = await Api.get(`order/backoffice?${parameters}`)
      response.data = orders.data
      return response
    } catch (error) {
      console.log("error : ",error)
      response.error = error
      return response
    }
  }

  async updateOrderStatus (order){
    let response = {}
    try {
      const notificationService = new NotificationService()
      const updatedOrder = await Api.put(`order/update/${order._id}`,order)
      const _orderData = { _id: order._id, urlFacture: order.urlFacture,}
      const notifcationData = {
        channel : "order",
        order: JSON.stringify(_orderData),
        title : "Status Commande",
        body: `Votre commande numéro ${order.numOrder} est ${order.status}`,
      }
      const customerTypes = []
      const customerId = order.customer
      console.log(notifcationData)
      console.log(customerId)
      await notificationService.pushNotification(notifcationData, customerTypes, customerId)
      response.data = updatedOrder.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async getOrderDetail (id){

    let response = {}
    try {
      const orderDetail = await Api.get(`order/detail/${id}`)
      response.data  = orderDetail.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async createOrderStatusHistory (data){
    let response = {}
    try {
      const res = await Api.post(`statusHistory/create`, data)
      response.data  = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async getOrderStatusHistory (orderId){
    let response = {}
    try {
      const res = await Api.get(`statusHistory?orderId=${orderId}`)
      response.data  = res.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

}