import React from 'react'

const NoData = ({label}) => {
  return (
    <div className='flex flex-column align-items-center justify-content-center bg-red-600'>
        <img src={'assets/layout/images/database.png'} width="60px" height="60px" />
        <p className='font-bold text-md mt-2 lowercase'>{label}</p>
    </div>
  )
}

export default NoData