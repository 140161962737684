import React from 'react'

const ImageTemplate = ({images,deletedImages,setFieldValue}) => {
    
    function onClickRemoveImage (url,photos, deletedImages ,setFieldValue){
        let undeletedPhotos = []
        undeletedPhotos = photos.filter((u) => u !== url)
        setFieldValue('photos',undeletedPhotos)
        let _deleteImages = deletedImages
        _deleteImages.push(url)
        setFieldValue('deletedImages',_deleteImages)
      }
    
    
      return(
        <div className='card'>
          <p className='font-bold'>Média</p>
          <div className='flex flex-wrap'>
          {
            images.map((url,index)=>(
              <div key={url} className='mr-3 mb-3 relative' 
              style={{height:'150px',width:'150px',boxShadow: '5px 5px 5px #0003',overflow:'hidden',borderRadius:5}}>
              <div
              onClick={() => onClickRemoveImage(url, images, deletedImages ,setFieldValue)} 
              className='flex align-items-center justify-content-center'
              style={{backgroundColor:'#fff',cursor:'pointer',
              width:30,height:30,borderRadius:15,
              position:'absolute',top:5,right:5,zIndex:10}}>
                <i className='pi pi-trash' style={{color:'#f00'}}></i>
              </div>
              <img height='150px' width='150px' alt='img' src={url} />
              </div>
            ))
          }
          </div>
        </div>
      )
}

export default ImageTemplate