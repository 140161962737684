import { atom } from "recoil";

export const customerPagination = atom({
  key: "customerPagination",
  default: {
    first: 0,
    rows: 10,
    page: 1,
    totalRecords: 0,
    filters: {
      customerType: null,
      active: null,
      city: null,
      nameEntreprise: null,
      phoneNumber: null,
      ISE: null,
      customerReference: null,
    },
  },
});
