import React from 'react'
import CustomInput from '../../CustomInput'

const StockBlock = ({
    values,
    handleChange,
    isFormFieldValid,
    getFormErrorMessage}) => {
  return (
    <div className='card'>
      <p className='font-bold mb-2'>Stock</p>
          <CustomInput
          label='Quantité stock'
          bold={false}
          handleChange={handleChange}
          value={values.quantityStock}
          name='quantityStock'
          isFormFieldValid={isFormFieldValid}
          getFormErrorMessage={getFormErrorMessage}
          keyfilter='pnum'/>
          
          <CustomInput
          bold={false}
          label='Quantité minimale'
          handleChange={handleChange}
          value={values.minOrderQuantity}
          name='minOrderQuantity'
          isFormFieldValid={isFormFieldValid}
          getFormErrorMessage={getFormErrorMessage}
          keyfilter='pnum'
          />
    </div> 
  )
}

export default StockBlock