import Api from "../utils/Api";

export class NotificationService {

  async pushNotification(data, customerTypes, customerId){
    let notificationData = {
      data,
      customerTypes
    }
    if(customerId)
      notificationData.customerId = customerId
    try {
      await Api.post("notification/push",notificationData)
    } catch (error) {
      console.log(error)
    }
  }
}