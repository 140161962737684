import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { OrderService } from '../service/OrderService';
import { Dropdown } from 'primereact/dropdown';
import { locale, addLocale } from 'primereact/api';
import StatusMenu from '../components/orders/StatusMenu';
import { onSnapshot, query,collection } from "firebase/firestore";
import { db } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';
import { statusCheck } from './orders/statusColor';
import NoData from '../components/NoData';


const STATUS=[
    {
        label:'Tous les status',
        value: null
    },
    {
        label:'EN COURS',
        value:'EN COURS'
    },
    {
        label:'ÉXPEDIÉ',
        value:'ÉXPEDIÉ'
    },
    {
        label:'LIVRÉ',
        value:'LIVRÉ'
    },
    {
        label:'RETOUR',
        value:'RETOUR'
    },
    {
        label:'ANNULÉE',
        value:'ANNULÉE'
    }
]

const Orders = () => {
    const orderService = new OrderService();
    const [orders, setOrders] = useState(null);
    //--------------------------------------------
    const [loading, setLoading] = useState(true);
    const [selectedCategory] = useState(null);
    const dt = useRef(null);
    const navigate = useNavigate()
    const [toggleOptions, setToggleOptions] = useState(null); // toggle options state
    const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 1,
        filters : {
            date: null, 
            status: null, 
            customer: null,
            numOrder: null
        },
        sortfield: null,
        sortorder: -1
    });

    addLocale('fr', {
        firstDayOfWeek: 1,
        dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
        dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
        dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre' ],
        monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jun', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc' ],
        today: "aujourd'hui",
        clear: 'réinitialiser'
    });
    
    locale('fr');


    useEffect(() => {
        lazyLoadData()
    }, [lazyParams]);

    //get the orders again when an order is created
    useEffect(() => {
        const q = query(collection(db, "orders"));
        const unsubscribe = onSnapshot(q, (snapshot) => {
          snapshot.docChanges().forEach((change) => {
            if (change.type === "modified") {
                setLazyParams({
                    first: 0,
                    rows: 10,
                    page: 1,
                    filters : {
                        date: null, 
                        status: null, 
                        customer: null,
                        numOrder: null
                    },
                    sortfield: null,
                    sortorder: -1
            })
            }
          });
        });
        return () => unsubscribe()
    }, [])

    // GET ORDERS
    async function lazyLoadData(){
        setLoading(true)
        const response = await orderService.getAllOrders(lazyParams,totalRecords)
        if(response.data){
            setOrders(response.data.orders)
            setTotalRecords(response.data.totalDocuments)
        } else {
            console.log(response.error)
        }
        setLoading(false)
    }

    const onPage = (event) => {
        setLazyParams({
            first: event.first,
            rows: 10,
            page: event.page + 1,
            filters : {
                date: lazyParams.filters.date, 
                status: lazyParams.filters.status, 
                customer: lazyParams.filters.customer,
                numOrder: lazyParams.filters.numOrder
            },
            sortfield: lazyParams.sortfield,
            sortorder: lazyParams.sortorder
        })
    }

    const onSort = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: event.page + 1,
            filters : {
                date: lazyParams.filters.date, 
                status: lazyParams.filters.status, 
                customer: lazyParams.filters.customer,
                numOrder: lazyParams.filters.numOrder
            },
            sortfield: event.sortfield,
            sortorder: lazyParams.sortorder * -1
        })
    }

    const onDateChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                date: event.target.value, 
                status: lazyParams.filters.status, 
                customer: lazyParams.filters.customer,
                numOrder: lazyParams.filters.numOrder
            },
            sortfield: null,
            sortorder: -1
        })
    }

    const onNumOrderChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                date: lazyParams.filters.date, 
                status: lazyParams.filters.status, 
                customer: lazyParams.filters.customer,
                numOrder: event.target.value
            },
            sortfield: null,
            sortorder: -1
        })

    }

    const onStatusChanged = (event) => {
        setLazyParams({
            first: 0,
            rows: 10,
            page: 1,
            filters : {
                date: lazyParams.filters.date, 
                status: event.value, 
                customer: lazyParams.filters.customer,
                numOrder: lazyParams.filters.numOrder
            },
            sortfield: null,
            sortorder: -1
        })
    }

    const updateOrderStatus = async (order) => {
        const response = await orderService.updateOrderStatus(order)
        if(response.error){
            console.log('status update',response.error)
        }
    } 

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions flex">

            <Button onClick={()=>navigate(`/orders/detail/${rowData._id}`)} 
                type="button" 
                icon="pi pi-eye" 
                className="p-button-sm p-button-secondary p-button-rounded p-button-outlined p-button-text" 
            />

            {/* STATUS CHANGE */}
            { (rowData.status !== 'RETOUR' || rowData.status !== 'ANNULÉE')
            && <StatusMenu 
            rowData={rowData} 
            setToggleMenu={setToggleMenu} 
            toggleMenu={toggleMenu} 
            updateOrderStatus={updateOrderStatus} />}

            </div>
        );
    }



    const statusBodyTemplate=(rowData)=>{
        const severity = statusCheck(rowData.status)
        return(
            <div style={{backgroundColor:severity,borderRadius:12,display:'inline-block'}} className='pr-2 pl-2'>
                <p className='text-sm font-medium lowercase' style={{color:'#fff'}}>{rowData.status}</p>
            </div>
        )
    }


    const priceBodyTemplate=(rowData)=>{
        return(
            <p>{rowData.totalPriceOrder}dh</p>
        )
    }

    const commandeFilter=()=>{
        return(
            <InputText 
            placeholder='numéro commande'
            className="p-inputtext-sm mr-2" 
            value={lazyParams.filters.numOrder} 
            onChange={onNumOrderChanged} />
        )
    }

    const dateFilter=()=>{
        return(
            <Calendar
            className="p-inputtext-sm mr-2"
            local={'fr'}
            style={{minWidth:'100px'}}
            showButtonBar
            onChange={onDateChanged} 
            value={lazyParams.filters.date}
            placeholder='date' 
            id="basic"/>
        )
    }


    const textTemplate=(rowData,field)=>{
        let val =''
        if(field==='numOrder') val = rowData.numOrder
        else if(field==='customer') {
            if(rowData.customer !== null)
            val = rowData.customer.nameEntreprise
            else val = "client n'existe plus" 
        }
        return(
        <p>{val}</p>
      )}

      const dateTemplate=(rowData)=>{
        const date = new Date(rowData.createdAt).toLocaleDateString("fr")
        const time = new Date(rowData.createdAt).toLocaleTimeString("fr")
        return (
            <p>{date} {time}</p>
        )
    }

      const statusFilter=()=>{
        return(
            <Dropdown 
            placeholder='STATUT'
            value={lazyParams.filters.status}
            options={STATUS}
            className="p-column-filter p-inputtext-sm"
            onChange={onStatusChanged}
            style={{width:150}} />
        )
      }



    return (
        <div className="grid crud-demo">
                <div className="col-12">

                <div className='mb-4 flex alignItems-center'>
                    <div className='flex align-items-center justify-content-center'>
                        <p className='text-2xl font-semibold'>Commandes</p>
                    </div>
                </div>

                    <div className="card">
                    <div className='overflow-x-auto mb-4 flex align-items-center justify-content-start'>
                        {commandeFilter()}
                        {dateFilter()}
                        {statusFilter()}
                    </div>

                    <DataTable 
                    size='small' 
                    lazy
                    first={lazyParams.first}
                    loading={loading}
                    totalRecords={totalRecords} 
                    paginator
                    onPage={onPage}
                    onSort={onSort}
                    ref={dt} 
                    value={orders} 
                    responsiveLayout="scroll"
                    rowHover 
                    selection={selectedCategory}
                    dataKey="id" 
                    rows={10} 
                    filterDisplay="row"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="afficher {first} à {last} de {totalRecords} commandes"
                    className="datatable-responsive"
                    emptyMessage={<NoData label='Aucune commande disponible'/>}>
                            <Column body={(val)=>textTemplate(val,'numOrder')} field="numOrder" header="# commande"></Column>
                            <Column field="createdAt" header="date" body={dateTemplate}></Column>
                            <Column body={(val)=>textTemplate(val,'customer')} field="customer.nameEntreprise" header="client"></Column>
                            <Column field="status" style={{minWidth:'90px'}} header="statut" body={statusBodyTemplate} ></Column>
                            <Column sortable field="totalPriceOrder" header="total" body={priceBodyTemplate}></Column>
                            <Column field="quantityTotal" header="quantité"></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                    </div>
                </div>
            </div>
      )
}

export default Orders