import React,{ useEffect, useState, memo } from 'react'
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { v4 as uuidv4 } from 'uuid';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Formik, Field } from 'formik';
import { classNames } from 'primereact/utils';
import * as Yup from 'yup'
import { VariantService } from '../../../service/VariantService';


const VariantsBlock = (
    {toast,
    options,
    variants,
    setVariants,
    productId,
    setOptions}) => {
    
    const variantService = new VariantService()
    const [selectedVariant, setSelectedVariant] = useState(null);

    // API CALL FOR VARIANTS BY PRODCUT ID
    const getVariants=async()=>{
        const response = await variantService.getVariantsByProduct(productId)
        if(response.data){
            setVariants(response.data)
        }else{
            toast.current.show({severity:'error', summary: 'Error', detail:'erreur de serveur, réessayez plus tard', life: 3000});
            console.log(response.error)
        }
    }

    useEffect(()=>{
        getVariants()
    },[])

    // ROW SELECT
    const onRowSelect=(e)=>{
        setSelectedVariant(e.value)
    }
    
    const footer = `nombre total de variantes est ${variants.length}`;

    const valuesBody=(rowData)=>{
        
        const renderOptionValue=(option)=>{
            if(option !== null){
                const checkHex = option.value.charAt(0) 
                if(checkHex === '#'){
                    return <div style={{backgroundColor:option.value,height:15,width:15,borderRadius:15/2}} />
                }else{
                    return <div> 
                            <p className='font-medium'>{`${option.value}`}</p>
                        </div>
                }
            }else return 
        }

        return(
            <div className='flex align-items-center'>
                {renderOptionValue(rowData.option1)}
                {rowData.option2 !== null && <div className='px-2'> <p>{`/`}</p> </div>}
                {renderOptionValue(rowData.option2)}
                {(rowData.option3 !== null && rowData.option2 !== null) && <div className='px-2'> <p>{`/`}</p> </div>}
                {renderOptionValue(rowData.option3)}
                {rowData?.new
                &&<div className='px-2 ml-2' 
                style={{backgroundColor:'#524aff',borderRadius:10}}>
                    <p className='text-xs' style={{color:'#fff'}}>nouveau</p>
                </div>
                }
            </div>
        )
    }

    const textEditor = (options) => {
        return <InputText type="text" 
        value={options.value} className='p-inputtext-sm w-full'
        onChange={(e) => options.editorCallback(e.target.value)} />;
    };

    const numberEditor = (options) => {
        return <InputText type="number" keyfilter="pnum"
        value={options.value} className='p-inputtext-sm w-full'
        onChange={(e) => options.editorCallback(e.target.value)} />;
    };

     // CALCULATE OPTION TOTAL QUANTITY
     const calculateTotalOptionValues=(_variants)=>{
        let newArr=[...options]

        for(let i in newArr){
           const vl = newArr[i].values
           for(let j in vl){
                vl[j].quantityStock = 0
           }
        }

        let indexVal
        _variants.map((_var,index)=>{
            if(_var.option1){
                indexVal =  newArr[0].values.findIndex((v) => v.name === _var.option1.value)
                if(indexVal !== -1 ){
                    newArr[0].values[indexVal].quantityStock += Number(_var.quantityStock)
                }
            }
            
            if(_var.option2){
                indexVal =  newArr[1].values.findIndex((v) => v.name === _var.option2.value)
                if(indexVal !== -1 ){
                newArr[1].values[indexVal].quantityStock += Number(_var.quantityStock)
                }
            }
        })
        setOptions(newArr)
        setVariants(_variants);
  
      }

    // EDIT ROW
    const onRowEditComplete = (e) => {
        let _variants = [...variants];
        let { newData, index } = e;
        
        if(newData.priceProduct === "") newData.priceProduct = 0
        if(newData.discountPrice === "") newData.discountPrice = 0
        if(newData.quantityStock === "") newData.quantityStock = 0
        if(newData.minOrderQuantity === "") newData.minOrderQuantity = 1

        _variants[index] = newData;
        calculateTotalOptionValues(_variants)
    };

    const rowClass = (data) => {
        return {
            'row-class': Number(data.priceProduct) === Number(data.discountPrice)
        }
    }

  return (
    <>
    {
        (options.length !== 0 && variants.length !== 0)
        && <div className='card relative'>
            <p className='font-bold'>Variantes</p>
            
            <div className='flex my-4'>
                <p style={{color:'#6D7175'}} className='font-normal text-md'>{'Sélectionner'}</p>
                <div onClick={()=>setSelectedVariant(variants)} className='ml-3 ' style={{cursor:'pointer'}} ><p className='filterBar font-normal text-md'>{'Toutes'}</p></div>
               <div onClick={()=>setSelectedVariant([])} className='ml-3 ' style={{cursor:'pointer'}}><p className='ml-3 filterBar font-normal text-md'>{'Aucune'}</p></div>
               { (selectedVariant && selectedVariant.length !== 0) 
               && <BulkEdit 
                    calculateTotalOptionValues={calculateTotalOptionValues}
                    setSelectedVariant={setSelectedVariant}
                    selectedVariant={selectedVariant} 
                    setVariants={setVariants} 
                    variants={variants} /> }
            </div>
            
             <DataTable
             rowHover
             stripedRows
             editMode="row"
             onRowEditComplete={onRowEditComplete}
             scrollable
             selection={selectedVariant} 
             selectionMode="checkbox"
             rowClassName={rowClass}
             onSelectionChange={onRowSelect} 
             dataKey={'_id'}
             scrollHeight="400px"
             value={variants}
             footer={footer} 
             size="small"
             responsiveLayout="scroll">
                    <Column selectionMode="multiple" frozen headerStyle={{width: '3em'}}></Column>
                    <Column  frozen style={{minWidth:'150px'}} headerStyle={{fontWeight: 300}} header="variant" body={valuesBody} ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => textEditor(options)} field="reference" header="reference" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options)} field="quantityStock" header="stock"></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options)} field="minOrderQuantity" header="qte minimal" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options)} field="priceProduct" header="prix original" ></Column>
                    <Column style={{minWidth:'120px'}} headerStyle={{fontWeight: 300}} editor={(options) => numberEditor(options)} field="discountPrice" header="prix de solde" ></Column>
                    <Column rowEditor style={{ minWidth: '6rem' }} alignFrozen="right" frozen bodyStyle={{ textAlign: 'center' }}></Column>
             </DataTable>
        </div>
    }
    </>
   
  )
}

export default memo(VariantsBlock)

const BulkEdit=({
    calculateTotalOptionValues,
    selectedVariant,
    setSelectedVariant,
    variants
    })=>{

    const [visible, setVisible] = useState(false);

    const initialValues = {
        quantityStock: 0,
        minOrderQuantity: 1,
        priceProduct: 0,
        discountPrice: 0
    }

    const validationSchema = Yup.object().shape({
        priceProduct: Yup.number().required('champ obligatoire'),
        quantityStock: Yup.number().required('champ obligatoire'),
        minOrderQuantity: Yup.number().required('champ obligatoire'),
        discountPrice: Yup.number().required('champ obligatoire'),
    })


    //create products
    const onSubmit=(values)=>{
        editSelectedVariantsEdit(values)
    }

    // BULK EDIT TYPE EDIT
    const editSelectedVariantsEdit=(values)=>{
        let _variants = [...variants];
        for(let i in _variants){
            for(let j in selectedVariant){
                if(_variants[i]._id === selectedVariant[j]._id){
                    _variants[i].quantityStock = values.quantityStock
                    _variants[i].minOrderQuantity = values.minOrderQuantity
                    _variants[i].priceProduct = values.priceProduct
                    _variants[i].discountPrice = values.discountPrice
                }
            }
        
        }
        setVisible(false)
        // setVariants(_variants);
        calculateTotalOptionValues(_variants)
        setSelectedVariant(null)
    }

    return (
        <>
        <div onClick={()=>setVisible(true)} className='ml-3 ' style={{cursor:'pointer'}}><p className='ml-3 filterBar font-normal text-md'>
            {`Modifier ${selectedVariant.length} variants`}
        </p></div>
        <Dialog 
        header={`Modifier ${selectedVariant.length} variants`} 
        draggable={false}
        visible={visible} 
        style={{ width: '50vw' }} 
        onHide={() => setVisible(false)} >
        
        
        <Formik 
        enableReinitialize={true}
        initialValues={initialValues} 
        validationSchema={validationSchema}
        onSubmit={onSubmit} >
        {({ handleChange, 
            handleSubmit,
            isSubmitting,
            handleBlur,
            values, 
            errors, 
            touched, 
            setFieldValue 
        }) =>
        {
            const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
            const getFormErrorMessage = (name) => {
                return isFormFieldValid(name) && <small className="p-error mt-2">{errors[name]}</small>;
            };
            return(
                <div className='grid'>

                    <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Quantité stock</p>
                        <InputText 
                        keyfilter="hex"
                        value={values.quantityStock}
                        onChange={handleChange('quantityStock')}
                        placeholder='quantité'
                        onBlur={handleBlur('quantityStock')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('quantityStock')})}
                        />
                        {getFormErrorMessage('quantityStock')}
                    </div>
                    </div>

                    <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Quantité minimal</p>
                        <InputText keyfilter="hex"
                        value={values.minOrderQuantity}
                        onChange={handleChange('minOrderQuantity') } 
                        placeholder='quantité minimal'
                        onBlur={handleBlur('minOrderQuantity')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('minOrderQuantity')})}
                        />
                        {getFormErrorMessage('minOrderQuantity')}
                    </div>
                    </div>

                    <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Prix original</p>
                        <InputText keyfilter="pnum"
                        value={values.priceProduct}
                        onChange={handleChange('priceProduct') } 
                        placeholder='prix original'
                        onBlur={handleBlur('priceProduct')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('priceProduct')})}
                        />
                        {getFormErrorMessage('priceProduct')}
                    </div>
                    </div>

                    <div className='col-12'>
                    <div className='w-full flex flex-column'>
                        <p className="capitalize mb-2">Prix de solde</p>
                        <InputText keyfilter="pnum"
                        onChange={handleChange('discountPrice') } 
                        placeholder='prix de solde'
                        value={values.discountPrice}
                        onBlur={handleBlur('discountPrice')}
                        className={classNames({ 'p-invalid':  isFormFieldValid('discountPrice')})}
                        />
                        {getFormErrorMessage('discountPrice')}
                    </div>
                    </div>

                    <div className='w-full flex justify-content-end align-items-center mt-2'>
                        <Button label="annuler" onClick={() => setVisible(false)} className="mr-2 p-button-text p-button-danger p-button-sm" />
                        <Button label="appliquer sur tous" onClick={handleSubmit} className="p-button-sm"/>
                    </div>
                </div>
            )
        }}
        </Formik>


        </Dialog>
        </>
    )
}

