import React from "react";
import { Dialog } from "primereact/dialog";

const ImageTemplate = ({ photos }) => {
  return (
    <>
      {photos.length === 0 ? (
        <i className="pi pi-image text-xl"></i>
      ) : (
        <ImageDialog img={photos[0]} />
      )}
    </>
  );
};

export default ImageTemplate;

const ImageDialog = ({ img }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <>
      <img
        style={{ cursor: "pointer", objectFit: "contain" }}
        onClick={() => setVisible(true)}
        src={img}
        alt={"image de produit"}
        width="30"
        height="30"
      />
      <Dialog
        onHide={() => setVisible(false)}
        draggable={false}
        visible={visible}
        style={{ width: "50vw" }}
      >
        <div className="flex align-items-center justify-content-center">
          <img
            style={{ objectFit: "contain" }}
            src={img}
            alt={"image de produit"}
            width="300"
            height="300"
          />
        </div>
      </Dialog>
    </>
  );
};
