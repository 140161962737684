import React,{ useEffect,useState } from 'react'
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { classNames } from 'primereact/utils';
import { BrandsService } from '../../../service/BrandsService';
import { CategoryService } from '../../../service/CategoryService';
import { LoadingComponent } from '../../LoadingComponents';


const CategoryBlock = ({
  selectedCategories,
  selectedUnderCategories,
  toast,
  marginBottom,
  handleChange,
  setFieldValue,
  isFormFieldValid,
  getFormErrorMessage}) => {

    const [brands, setBrands] = useState([])
    const [categories, setCategories] = useState([])
    const [categoriesLoading, setCategoriesLoading] = useState(true)
    const categoryService = new CategoryService()
    const brandsService = new BrandsService()

    //get categories when the model is open
    useEffect(() => {
      getAllCategories()
    },[])

    //this is for the edit
    useEffect(()=>{
        if(selectedCategories != null){
        getBrandsBySelectedCategory(selectedCategories)
    }
    },[])

    async function getAllCategories () {
      const response = await categoryService.getCategories()
      if(response.data){
          setCategories(response.data)
          setCategoriesLoading(false)
      } else {
          toast.current.show({severity:'error', summary: 'Error', detail:'erreur de catégorie essayer plus tard', life: 3000});
          console.log(response.error)
          setCategoriesLoading(false)
      }
  }

    async function getBrandsBySelectedCategory(category) {
      const response = await brandsService.getBrandsByCategory(category)
      if(response.data){
          setBrands(response.data)
      } else {
          toast.current.show({severity:'error', summary: 'Error', detail:'erreur essayer plus tard', life: 3000});
          console.log(response.error)
      }
  }
    
  if(categoriesLoading){
    return <LoadingComponent title={'Catégorie et marque'} />
  }
  
  return (
    <div className='card'>
            <p className='font-bold mb-2'>Catégorie et marque</p>
            <div>
            <p>catégorie</p>
                {/* <Dropdown 
                value={category}
                onChange={(e) => { handleChange('category')(e); getBrandsBySelectedCategory(e.value)}}
                options={categories}
                placeholder = "choisissez une catégorie"
                className={`w-full ${classNames({ 'p-invalid':  isFormFieldValid('category')})}`} /> 
                {getFormErrorMessage('category')} */}
                
                <MultiSelect
                filter
                value={selectedCategories} 
                onChange={(e) => { 
                    console.log("selected cat changed")
                    handleChange('selectedCategories')(e); 
                    setFieldValue('selectedUnderCategories',null)
                    getBrandsBySelectedCategory(e.value)
                }}
                options={categories}
                optionLabel="nameCategory" 
                optionValue="_id"
                placeholder="choisissez une catégorie" 
                className={`w-full ${classNames({ 'p-invalid':  isFormFieldValid('selectedCategories')})}`}  
                />
                {getFormErrorMessage('selectedCategories')}
            </div>

                
            {
            selectedCategories != null && brands.length !== 0
            &&<div className='mt-4'>
                <p className="mb-2">marque</p>
                <div className='flex align-items-center'>
                    <MultiSelect
                    filter
                    value={selectedUnderCategories} 
                    onChange={handleChange('selectedUnderCategories')}
                    options={brands}
                    optionLabel="nameUnderCategory" 
                    optionValue="_id"
                    placeholder="sélectionner une marque" 
                    />
                    <Button 
                    onClick={() => setFieldValue('selectedUnderCategories',null) }
                    icon="pi pi-trash" 
                    className="ml-2 p-button-danger" aria-label="filter" />
             
                </div>
            </div>
            }
            </div>
  )
}

export default CategoryBlock