import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { ProductService } from "../service/ProductService";
import { CategoryService } from "../service/CategoryService";
import { InputText } from "primereact/inputtext";
import OptionsMenu from "../components/OptionsMenu";
import { useNavigate } from "react-router-dom";
import ImageTemplate from "../components/orders/ImageTemplate";
import NoData from "../components/NoData";

import SingleDelete from "../components/SingleDelete";
import { useRecoilState } from "recoil";
import { paginationAtom, totalProductsAtom } from "../atoms/ProductPaginationAtom";
import { BrandsService } from "../service/BrandsService";

const Products = () => {
  const productsService = new ProductService();
  const brandService = new BrandsService();

  const STATUS = [
    { label: "tout", value: null },
    { label: "actif", value: true },
    { label: "inactif", value: false },
  ];

  const REMISE_OPTIONS = [
    { label: "tout", value: null },
    { label: "avec remise", value: true },
    { label: "sans remise", value: false },
  ];

  const [lazyParams, setLazyParams] = useRecoilState(paginationAtom);
  const [totalRecords, setTotalRecords] = useRecoilState(totalProductsAtom);

  const [loading, setLoading] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(null); // toggle menu state

  const [products, setProducts] = useState(null);
  const [categories, setCategories] = useState(null);
  const [brands, setBrands] = useState(null);
  const [loadingBrands, setLoadingBrands] = useState(false);
  const navigate = useNavigate();
  const dt = useRef(null);

  console.log("lazyParams", lazyParams);

  //get categories
  useEffect(() => {
    getCategories();
  }, []);

  //load products
  async function lazyLoadData() {
    setLoading(true);
    const response = await productsService.getProducts(
      lazyParams,
      totalRecords
    );
    if (response.data) {
      setProducts(response.data.products);
      setTotalRecords(response.data.totalDocuments);
    } else {
      console.log(response.error);
    }
    setLoading(false);
  }



  // get products
  useEffect(() => {
    lazyLoadData();
  }, [lazyParams]);

  useEffect(() => {
    console.log("selected category changed ");
    if (lazyParams.filters.selectedCategory == null && brands != null) {
      setBrands(null);
    }
    if (lazyParams.filters.selectedCategory != null) {
      getBrandsBySelectedCategory(lazyParams.filters.selectedCategory);
    }
  }, [lazyParams.filters.selectedCategory]);

  //get categories
  async function getCategories() {
    const categoryService = new CategoryService();
    const response = await categoryService.getCategories();
    let _categories = [
      {
        label: "Toutes les catégories",
        value: null,
      },
    ];
    let _categories2 = [];
    if (response.data) {
      response.data.map((category) => {
        _categories.push({
          label: category.nameCategory,
          value: category._id,
        });
      });
      setCategories(_categories);
    } else {
      console.log(response.error);
    }
  }

  async function getBrandsBySelectedCategory(selectedCategory) {
    console.log("selectedCategory: ", selectedCategory);
    setLoadingBrands(true);
    const response = await brandService.getBrandsByCategory([selectedCategory]);
    if (response.data) {
      setBrands(response.data);
    } else {
      console.log(response.error);
    }
    setLoadingBrands(false);
  }

  const deleteProduct = async (productId) => {
    const response = await productsService.removeProduct(productId);
    if (response.data) {
      lazyLoadData();
    } else {
      console.log(response.error);
    }
  };

  const statusBodyTemplate = (rowData) => {
    const CustomBadge = (bg, status) => {
      return (
        <div
          style={{ backgroundColor: bg, borderRadius: 10 }}
          className="px-2 w-max"
        >
          <p className="text-sm">{status}</p>
        </div>
      );
    };
    return (
      <>
        {rowData.active ? (
          <>{CustomBadge("#A1D8C1", "Actif")}</>
        ) : (
          <>{CustomBadge("#d8a1a1", "Inactif")}</>
        )}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <OptionsMenu
        rowData={rowData}
        setToggleMenu={setToggleMenu}
        toggleMenu={toggleMenu}
      >
        {rowData._id === toggleMenu && (
          <div className="pb-2 pt-2 options-menu">
            <div
              className="align-items-center flex p-2 pl-3 pr-6 menu-child"
              onClick={() =>
                navigate(`/products/${rowData?._id}`, { state: rowData })
              }
            >
              <i className="pi pi-eye"></i>
              <span className="uppercase ml-2">aperçu</span>
            </div>
            <div
              className="align-items-center flex p-2 pl-3 pr-6 menu-child"
              onClick={() => navigate(`/products/edit/${rowData?._id}`)}
            >
              <i className="pi pi-pencil"></i>
              <span className="uppercase ml-2">modifier</span>
            </div>
            <SingleDelete
              id={rowData?._id}
              name={rowData?.nameProduct}
              deleteFunction={() => deleteProduct(rowData?._id)}
              setToggleMenu={setToggleMenu}
            />
          </div>
        )}
      </OptionsMenu>
    );
  };

  const quantityTemplate = (rowData) => {
    return (
      <>
        {rowData?.quantityStock <= 20 ? (
          <div className="flex align-items-center">
            <i
              className="pi pi-exclamation-circle"
              style={{ color: "#f00" }}
            ></i>
            <div>
              <p className="ml-1" style={{ color: "#f00" }}>
                {rowData?.quantityStock}
              </p>
            </div>
          </div>
        ) : (
          <div>
            <p>{rowData?.quantityStock}</p>
          </div>
        )}
      </>
    );
  };

  const priceTemplate = (rowData) => {
    // if(rowData.maxPrice.price === rowData.minPrice.price)
    return <p>{rowData.priceProduct.toFixed(2)}dh</p>;
    // else return <p>{`${rowData.maxPrice.price} - ${rowData.minPrice.price}dh`}</p>
  };

  const remiseTemplate = (rowData) => {
    return <p>{rowData.discountPercentage} %</p>;
  };

  const categoryTemplate = (rowData) => {
    let _categories = ``;
    rowData.category.map(({ nameCategory }, index) => {
      if (index + 1 == rowData.category.length) _categories += nameCategory;
      else _categories += `${nameCategory} - `;
    });

    return <p>{_categories}</p>;
  };

  const onPage = (event) => {
    setLazyParams({
      ...lazyParams,
      first: event.first,
      rows: 10,
      page: event.page + 1,
    });
  };

  const onSort = (event) => {
    console.log("event sort: ", event);
    let sortorder;
    if (
      lazyParams.sortfield == null ||
      lazyParams.sortfield != event.sortField
    ) {
      sortorder = 1;
    } else {
      sortorder = lazyParams.sortorder * -1;
    }
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: { ...lazyParams.filters },
      sortfield: event.sortField,
      sortorder: sortorder,
    });
  };

  const onChangeCategory = async (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        ...lazyParams.filters,
        selectedUnderCategory: null,
        selectedCategory: event.value,
      },
      sortfield: null,
      sortorder: -1,
    });
  };

  const onChangeUnderCategory = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        ...lazyParams.filters,
        selectedUnderCategory: event.value,
      },
      sortfield: null,
      sortorder: -1,
    });
  };

  const onNameProductChanged = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        ...lazyParams.filters,
        nameProduct: event.target.value,
      },
      sortfield: null,
      sortorder: -1,
    });
  };

  const onChangeStatus = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        ...lazyParams.filters,
        active: event.value,
      },
      sortfield: null,
      sortorder: -1,
    });
  };

  const onChangeRemise = (event) => {
    setLazyParams({
      first: 0,
      rows: 10,
      page: 1,
      filters: {
        ...lazyParams.filters,
        remise: event.value,
      },
      sortfield: null,
      sortorder: -1,
    });
  };

  console.log("selected cat: ", lazyParams.filters.selectedCategory);

  //--------------FILTERS-----------------------------
  const categoryFilter = () => {
    return (
      <Dropdown
        filter
        value={lazyParams.filters.selectedCategory}
        placeholder="Toutes les catégories"
        options={categories}
        onChange={onChangeCategory}
        className="p-column-filter p-inputtext-sm mr-2"
      />
    );
  };

  const underCategoryFilter = () => {
    return (
      <>
        {loadingBrands ? (
          <i className={"pi pi-spin pi-spinner"} />
        ) : (
          <Dropdown
            filter
            value={lazyParams.filters.selectedUnderCategory}
            placeholder="Toutes les marques"
            options={brands}
            optionLabel="nameUnderCategory"
            optionValue="_id"
            onChange={onChangeUnderCategory}
            className="p-column-filter p-inputtext-sm mr-2"
          />
        )}
      </>
    );
  };

  const statusFitler = () => {
    return (
      <Dropdown
        value={lazyParams.filters.active}
        placeholder="Statut"
        options={STATUS}
        onChange={onChangeStatus}
        className="p-column-filter p-inputtext-sm"
      />
    );
  };

  const nameProductFilter = () => {
    return (
      <InputText
        className="mr-2 p-inputtext-sm"
        placeholder="nom de produit"
        onChange={onNameProductChanged}
        value={lazyParams.filters.nameProduct}
      />
    );
  };

  const remiseFilter = () => {
    return (
      <Dropdown
        value={lazyParams.filters.remise}
        placeholder="Remise"
        options={REMISE_OPTIONS}
        onChange={onChangeRemise}
        className="p-column-filter p-inputtext-sm"
      />
    );
  };

  const paginatorLeft = (
    <Button onClick={lazyLoadData} type="button" icon="pi pi-refresh" text />
  );

  return (
    <div className="relative grid crud-demo">
      <div className="col-12">
        <div>
          <div className="mb-4 flex alignItems-center justify-content-between">
            <div className="flex align-items-center justify-content-center">
              <p className="text-2xl font-semibold">Produits</p>
            </div>
            <Button
              icon="pi pi-plus"
              onClick={() => navigate("/products/add", { replace: true })}
              label="nouveau produit"
            />
          </div>

          <div className="card">
            <div className="overflow-x-auto mb-4 flex align-items-center justify-content-start">
              {categoryFilter()}
              {underCategoryFilter()}
              {nameProductFilter()}
              {statusFitler()}
              {remiseFilter()}
            </div>

            <DataTable
              paginator
              paginatorLeft={paginatorLeft}
              lazy
              first={lazyParams.first}
              rows={10}
              loading={loading}
              totalRecords={totalRecords}
              onPage={onPage}
              onSort={onSort}
              filterDisplay="row"
              stripedRows
              ref={dt}
              rowHover
              responsiveLayout="scroll"
              value={products}
              size="small"
              dataKey="id"
              className="datatable-responsive"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
              emptyMessage={<NoData label="Aucun produit disponible" />}
            >
              <Column
                field="category"
                header="categorie"
                body={categoryTemplate}
              ></Column>
              <Column field="nameProduct" header="nom"></Column>
              <Column
                field="photos"
                header="image"
                body={(rowData) => <ImageTemplate photos={rowData.photos} />}
              ></Column>
              <Column
                sortable
                field="priceProduct"
                header="prix"
                body={priceTemplate}
              ></Column>
              <Column
                sortable
                field="discountPercentage"
                header="remise"
                body={remiseTemplate}
              ></Column>
              <Column
                sortable
                field="quantityStock"
                header="quantité"
                body={quantityTemplate}
              ></Column>
              <Column
                field="active"
                header="status"
                body={statusBodyTemplate}
              ></Column>
              <Column body={actionBodyTemplate}></Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Products);
