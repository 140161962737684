import React,{ memo } from 'react'
import CustomInput from '../../CustomInput'


const PriceBlock = ({
  values,
  handleChange,
  isFormFieldValid,
  getFormErrorMessage}) => {
return (
  <div className='card'>
    <p className='font-bold mb-2'>Prix</p>
        <CustomInput
        label='Prix initial'
        bold={false}
        handleChange={handleChange}
        value={values.priceProduct}
        name='priceProduct'
        isFormFieldValid={isFormFieldValid}
        getFormErrorMessage={getFormErrorMessage}
        keyfilter='pnum'/>
        
        <CustomInput
        bold={false}
        label='pourcentage de remise'
        handleChange={handleChange}
        value={values.discountPercentage}
        name='discountPercentage'
        isFormFieldValid={isFormFieldValid}
        getFormErrorMessage={getFormErrorMessage}
        keyfilter='pnum'
        />
  </div> 
)
}

export default memo(PriceBlock)