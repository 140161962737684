import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import { InputSwitch } from 'primereact/inputswitch';
import { FileUpload } from 'primereact/fileupload';
import { v4 as uuidv4 } from 'uuid';
import { ImageService } from '../../service/ImageService';
import CustomInput from '../CustomInput';
import CustomSwitch from '../CustomSwitch';

const EditBrand = ({categories,rowData,updateSubCategory}) => {

    const imageService = new ImageService()
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const [numberFiles, setNumberFiles] = useState(0)
    const [selectedCategories, setSelectedCategories] = useState(null)
    const hideDialog = () => setDialogVisibility(false)
    const openModal = () => setDialogVisibility(true)
    let fileUploadRef = useRef(null)
    const [loading,setLoading] = useState(false);

    useEffect(() => {
        if(dialogVisibility){
            const _selectedCat = rowData.category.map(({ _id }) => _id)
            setSelectedCategories(_selectedCat)
        }
    }, [dialogVisibility])

    const initialValues = {
        _id:rowData._id,
        name: rowData.nameUnderCategory,
        selectedCategories: selectedCategories,
        image: rowData?.photo?.url,
        status:rowData.active,
        deletedImage: null
    }
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('nom de marque obligatoire'),
        selectedCategories: Yup.array().min(1,"catégorie obligatoire").required(),
        status:Yup.bool()
    })

    const onSubmit = async (values,actions) => {
       if(values.deletedImage != null ){
            fileUploadRef.upload()
        }
        else {
            const data = {
                nameUnderCategory: values.name,
                category: values.selectedCategories,
                active: values.status,
                photo: {url: values.image}
            }
            
            await updateSubCategory(values._id,data)
            hideDialog()
        }
       
    }

    const clearUrl = (url,setFieldValue)=>{
        if(url == null) url=''
        setFieldValue('deletedImage',url)
        setFieldValue('image',null)
    }

    //-----------handle template images-------------//
    //when the selected images uploaded
    const myUploader = async(values,event) => {
        const files = event.files
        const blob = await fetch(files[0]?.objectURL).then(r => r.blob()); //get blob url
        const category_url = await imageService.uploadImage(blob,`brands/${files[0].name}${uuidv4()}`) // upload to firebase and get url
        const data = {
            nameUnderCategory: values.name,
            category: values.selectedCategories,
            active: values.status,
            photo: {url: category_url?.data}
        }
        console.log(data)
        await imageService.deletImage(values.deletedImage)
        await updateSubCategory(values._id,data)
        hideDialog()
    }

    //when an image added
    const onTemplateSelect = (e,setFieldValue) => {
        setNumberFiles(1)
        setFieldValue('image','image')
    }

    //when an image removed
    const onTemplateRemove = (e,setFieldValue) => {
        setNumberFiles(0)
        setFieldValue('image',null)
    }

    //when all images removed
    const onTemplateClear = (e,setFieldValue) => {
        setNumberFiles(0)
        setFieldValue('image',null)

    }

    const headerTemplate = (options, deletedImage) => {
        const { className, chooseButton, cancelButton } = options;
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {deletedImage != null  && chooseButton}
                {cancelButton}
            </div>
        );
    }


  return (
    <>
    <Button icon="pi pi-pencil" className="p-button-sm p-button-rounded p-button-text p-button-warning" onClick={openModal} />
    
    {/* MODAL */}
    <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
        header="Modifier marque" modal 
        className="p-fluid" onHide={hideDialog}>
        
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ 
            handleChange,handleBlur, 
            handleSubmit,
            isSubmitting, 
            values, 
            setFieldValue,
            errors, 
            touched })=>{
                
            const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
            const getFormErrorMessage = (name) => {
                return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
            };

            console.log(values.selectedCategories)

            return(
            <>

            {/* NAME */}
            <CustomInput
                label='nom de la marque ou sous catégorie'
                placeholder='nom'
                handleChange={handleChange}
                value={values.name}
                name='name'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
            />
                
            {/* CATEGORY */}
            <div className='mb-3'>
                <p className="mb-2">catégorie</p>
                <MultiSelect 
                        value={values.selectedCategories} 
                        onChange={handleChange('selectedCategories')}
                        options={categories}
                        optionLabel="nameCategory" 
                        optionValue="_id"
                        placeholder="choisissez une catégorie" 
                        className={classNames({ 'p-invalid':  isFormFieldValid('selectedCategories')})}  
                    />
                {getFormErrorMessage('selectedCategories')}
            </div>
           

            {/* IMAGE DISPLAY */}
            {values.deletedImage == null && values
            && <div style={{width:150,height:150}} 
            className="relative field flex align-items-center ">
            <img src={values.image} alt={values.name} width='150' height='150'/>
            <div 
            onClick={()=> clearUrl(values.image,setFieldValue) }
            className='flex align-items-center justify-content-center'
            style={{position:'absolute',top:5,right:5,height:40,width:40,borderRadius:20,cursor:'pointer',backgroundColor:'#fff'}}>
                    <i className={loading?'pi pi-spin pi-spinner':'pi pi-trash'} 
                    style={{color:'#f00'}}></i>
            </div>
            </div>
            }
            {/* template image */}
            {values.deletedImage !== null && 
            <FileUpload 
            mode="advanced" 
            ref={(el) => fileUploadRef = el}
            name="demo[]"
            customUpload 
            url="https://primefaces.org/primereact/showcase/upload.php" 
            accept="image/*" 
            maxFileSize={2000000}
            headerTemplate={(options) => headerTemplate(options,values.deletedImage)}
            uploadHandler={(e) => myUploader(values,e)}
            onSelect={(e) => onTemplateSelect(e,setFieldValue)}
            onError={(e) => onTemplateClear(e,setFieldValue)} 
            onClear={(e) => onTemplateClear(e,setFieldValue)} 
            onRemove={(e) => onTemplateRemove(e,setFieldValue)}
            emptyTemplate={<p className="m-0">Faites glisser et déposez l'image ici pour le télécharger.</p>}
            />
            }

             {/* STATUS */}
             <div className='mb-3'>
            <CustomSwitch
                label='status'
                name='status'
                active={values.status} 
                handleChange={handleChange}/>
            </div>
    
      

        <div className='mt-4 flex align-items-center justify-content-end'>
            
            <Button label="annuler" 
            className='w-auto p-button-outlined p-button-sm p-button-secondary mr-2' icon="pi pi-times" 
            onClick={hideDialog}/>

            <Button onClick={handleSubmit} 
            label={isSubmitting?'loading':'modifier'}
            disabled={isSubmitting}
            loading={isSubmitting}
            className='w-auto p-button-sm p-button-warning' 
            icon="pi pi-pencil"  type='submit' />

        </div>
        </>
            )
        }}
        </Formik>
        
        </Dialog>
    </>
  )
}

export default EditBrand