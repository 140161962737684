import React, { useState, useEffect, useRef } from 'react';
import {
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { proposalCount } from '../atoms/Atom'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ProposalsService } from '../service/ProposalsService';
import NoData from '../components/NoData';
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import PreviewSuggestion from '../components/suggestions/PreviewSuggestion';
import { Button } from 'primereact/button';



const Proposals = () => {
  
  const propsalsService = new ProposalsService()


  const dt = useRef(null);
  const [_count, setCount] = useRecoilState(proposalCount);
  const [proposals, setProposals] = useState(null)
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1
  })
  const [totalRecords, setTotalRecords] = useState(0)
  const [loading, setLoading] = useState(false)

  const lazyLoadData = async () => {
    setLoading(true)
    const response = await propsalsService.getPropsals(lazyParams, totalRecords)
    if(response.data){
      setProposals(response.data.suggestions)
      setTotalRecords(response.data.totalDocuments)
    } else {
      console.log(response.error)
    }
    setLoading(false)
  }

  useEffect(() => {
    lazyLoadData()
  },[lazyParams])

  const header = (
    <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
        <h5 className="m-0">Les propositions des clients</h5>
    </div>
);

  const dateTemplate = (rowData) => {
    const date = new Date(rowData.createdAt).toLocaleDateString("fr")
    return date
  }

  const onPage = (event) => {
    setLazyParams({
      first: event.first,
      rows: 10,
      page: event.page + 1,
    })
  }

  // UPDATE READ STATUS FUNCTION
  const updateStatus=async(id)=>{
    const res = await propsalsService.updateStatus(id,{read:true})
    if(res.data){
      lazyLoadData()
      setCount(uuidv4())
    }else{
      toast.error("erreur réessayez plus tard")
    }
  }


    // DISPLAY SUGGESTION MODAL
    const actionBodyTemplate = (rowData) => {
      return (
          <div className="actions">
             <PreviewSuggestion rowData={rowData} />
          </div>
      );
  }

  // IMAGE TEMPLATE
  const imageBodyTemplate=(rowData) => {
    if(rowData?.images?.length > 0){
      return <p className='text-primary font-bold'>oui</p>
    }
    return <p>non</p> 
  }

  // SUGGESTION READ STATUS TEMPLATE
  const readTemplate=(rowData)=>{
    if(rowData.read)
     return <Button icon="pi pi-eye-slash" 
     className="p-button-sm p-button-rounded p-button-text p-button-secondary"/>
    else
      return <Button icon="pi pi-eye" 
      className="p-button-sm p-button-rounded p-button-text p-button-danger" 
      onClick={()=>updateStatus(rowData?._id)} />
  }


  return (
    <div className="grid crud-demo">
            <div className="col-12">

                <div className="card">
                    <DataTable size='small' 
                    lazy first={lazyParams.first} totalRecords={totalRecords}
                    onPage={onPage}
                    loading={loading}
                    stripedRows 
                    paginator
                    ref={dt} 
                    value={proposals} 
                    responsiveLayout="scroll"
                    rowHover 
                    header={header}
                    dataKey="id" 
                    rows={10} 
                    filterDisplay="row"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="afficher {first} à {last} de {totalRecords} propositions"
                    className="datatable-responsive"
                    emptyMessage={<NoData label='Aucune proposition disponible' />}>
                            <Column body={dateTemplate} field="createdAt" header="date"></Column>
                            <Column  field="customer.nameEntreprise" header="client"></Column>
                            <Column style={{maxWidth:300}} field="body" header="demande"></Column>
                            <Column field="images" header="images" body={imageBodyTemplate}></Column>
                            <Column field="read" header="bien reçu" body={readTemplate}></Column>
                            <Column body={actionBodyTemplate}></Column>
                    </DataTable>
                </div>

            </div>
        </div>
  )
}

export default Proposals