import React, {forwardRef} from 'react'
import { FileUpload } from 'primereact/fileupload'


const ImageUpload = ({myUploader,assignRef,values,setFieldValue}) => {

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;    
        return (
            <div className={className} style={{backgroundColor: 'transparent', display: 'flex', alignItems: 'center'}}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    }

    const onTemplateSelect = (e, setFieldValue) => {
        setFieldValue("numberImages",e.files.length)
    }

    const onTemplateClear = (e, setFieldValue) => {
        setFieldValue("numberImages",0)
    }

    const onTemplateRemove = (e, numberImages ,setFieldValue) => {
        setFieldValue("numberImages",numberImages - 1)
    }
    

  return (
    <div className='card'>
        <p className='font-bold'>Images</p>
        <FileUpload name="demo[]" url="https://primefaces.org/primereact/showcase/upload.php" 
            ref={(el) => assignRef(el)} 
            multiple
            headerTemplate={headerTemplate}
            accept="image/*" 
            maxFileSize={2000000}
            customUpload
            uploadHandler={(e) => myUploader(values,e)}
            onSelect={(e) => onTemplateSelect(e,setFieldValue)}
            onError={(e) => onTemplateClear(e, setFieldValue)} 
            onClear={(e) => onTemplateClear(e, setFieldValue)} 
            onRemove={(e) => onTemplateRemove(e, values.numberImages, setFieldValue)}
            emptyTemplate={<p className="m-0">Faites glisser et déposez les images ici pour les télécharger.</p>} />                     
    </div>
  )
}

export default ImageUpload