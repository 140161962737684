import React,{ useState } from 'react'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { AppConfig } from '../AppConfig'
import { Formik } from 'formik';
import * as Yup from 'yup'
import { classNames } from 'primereact/utils';
import api from '../utils/Api'
import { ToastContainer, toast } from 'react-toastify';
import config from '../config/app.config.json'
import 'react-toastify/dist/ReactToastify.css';


import { AdminService } from '../service/AdminService';

const Login = () => {

    const adminService = new AdminService()
    const [inpuType,setType]=useState('password')

    const initialValues = {
        username: '',
        password: ''
    }
    
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('champ obligatoire'),
        password: Yup.string().required('champ obligatoire'),
    })

    const onSubmit = async (values,actions) => {

        const response = await adminService.login(values.username, values.password)
        if(response.data){
            const { token, admin } = response.data
            window.localStorage.setItem('userToken', token);
            window.localStorage.setItem('userInfo', JSON.stringify(admin));
            window.localStorage.setItem('isLogged', true);
            window.location.replace("/");
        } else {
            const error = response.error;
            if(error.response){
                toast.error(error.response.data.message);
            } else {
                toast.error("erreur réessayez plus tard")
            }
        }

    }

    return (
        <>
        <div className="h-screen flex align-items-center justify-content-center">
            <div 
            style={{borderRadius:10}}
            className='flex flex-column p-5 align-items-center justify-content-center bg-white w-12 sm:w-7 l:w-5 xl:w-4'>
                <div>
                    <img style={{objectFit:'contain'}} src='assets/layout/images/logo.png' 
                    width='200px' height='200px' alt='logo' />
                </div>
                <div className='w-full'>
                    <h3 className='w-full font-bold'>{`Bienvenu au ${config.helmet} admin`}</h3>
                    <p className='w-full'>{`entrez vos identifiants pour vous connecter`}</p>
                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                {({ 
                    handleChange,
                    handleBlur, 
                    handleSubmit,
                    isSubmitting, 
                    values, 
                    errors, 
                    touched })=>{
        
                    const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                    const getFormErrorMessage = (name) => {
                        return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                    };
    
                    return(
                    <div className='w-full mt-4'>
                        <div className="field flex flex-column" >
                            <input
                            onChange={handleChange('username')} 
                            onBlur={handleBlur('username')}
                            className={classNames({ 'c-input-invalid':  isFormFieldValid('username')},'c-input')}
                            placeholder='email'/>
                        </div>
    
                        <div className="field flex flex-column">
                            
                            <div className='flex w-full'>
                            <input 
                            onChange={handleChange('password')} 
                            onBlur={handleBlur('password')}
                            type={inpuType} 
                            placeholder='mot de pass'
                            className={classNames({ 'c-input-invalid':  isFormFieldValid('password')},'c-input w-full')}/>
                            <div 
                            onClick={()=>setType(inpuType==='password'?'text':'password')}
                            style={{backgroundColor:'#eee',borderRadius:15,width:45,cursor:'pointer'}} 
                            className='flex align-items-center justify-content-center ml-2'>
                                <i className={inpuType==='password'?'pi pi-eye':'pi pi-eye-slash'}></i>
                            </div>
                            </div>
                        </div>
    
                        {/* <div className='mb-3'>
                            <p onClick={()=>navigate('/forgetpassword',{replace:true})} style={{cursor:'pointer'}} className='underline'>mot de pass oublié</p>
                        </div> */}
    
                        
                        <Button 
                        style={{backgroundColor:config.mainColor,borderColor:config.mainColor}}
                        onClick={handleSubmit} 
                        label={isSubmitting ? "" : "se connecter" }
                        type='submit'
                        loading={isSubmitting}
                        disabled={isSubmitting}
                        className='p-buttom-xl w-full' />
                    
                </div>
                    )
                }}
                </Formik>
            </div>
        </div>
        <AppConfig/>
        </>
        )
}

export default Login