import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button'; 
import { Dialog } from 'primereact/dialog'; 
import SuggestionImageModal from './SuggestionImageModal';

const PreviewSuggestion = ({rowData}) => {

  const { images, body, customer } = rowData

    const [dialogVisibility, setDialogVisibility] = useState(false); 
    const hideDialog = () => setDialogVisibility(false)
    const openModal = () => setDialogVisibility(true)
 

  return (
    <>
    <Button icon="pi pi-window-maximize" 
    className="p-button-sm p-button-rounded p-button-text p-button-primary" 
    onClick={openModal} />
    
    {/* MODAL */}
    <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
        header="Détail de la demande" modal 
        className="p-fluid" onHide={hideDialog}>

          {customer && 
          <div className='mb-3'>
            <p className='font-bold'>client</p>
            <p>{customer?.nameEntreprise}</p>
          </div>}


          <div className='mb-3'>
            <p className='font-bold'>description</p>
            <p>{body}</p>
          </div>


        {images.length > 0
          &&
          <div className='flex flex-column mb-3'>
            <p className='font-bold'>images</p>
          <div className='flex-wrap'>
          {
            images.map(photo => (
              <SuggestionImageModal photo={photo} />
            ))
          }
        </div>
        </div>
        }

        </Dialog>
    </>
  )
}

export default PreviewSuggestion