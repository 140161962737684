import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyC6g98rRzKYmBa4Ed7ZX0plz_iqx6o9k3Y",
    authDomain: "cpopara.firebaseapp.com",
    projectId: "cpopara",
    storageBucket: "cpopara.appspot.com",
    messagingSenderId: "757416392127",
    appId: "1:757416392127:web:0f78f5bc176b520188dd0a",
    measurementId: "G-NRSQP6CXYK"
};

export const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app)