import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { classNames } from 'primereact/utils';
import { Formik } from 'formik';
import * as Yup from 'yup'
import PasswordBlock from '../../components/customers/PasswordBlock';
import CustomInput from '../CustomInput';

const AddAdmin = ({ createSousAdmin }) => {
    const [dialogVisibility, setDialogVisibility] = useState(false);
    const hideDialog = () => setDialogVisibility(false)
    const openNew = () => setDialogVisibility(true)

    const initialValues = {
        name: '',
        email:'',
        password: '',
    }
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('nom de livreur obligatoire'),
        email: Yup.string().email("saisir un email valide").required("numero de telephone obligatoire"),
        password: Yup.string().min(8, "entrez 8 caractère au minimum").required("mot de passe obligatoire")

    })

    const onSubmit = async (values,actions) => {
        console.log(values)
        await createSousAdmin(values)
        hideDialog()
    }
    
    
    return(
        <>
        <Button 
        label="ajouter nouveau admin" 
        icon="pi pi-plus" 
        className="mr-2" 
        onClick={openNew} />
        
        {/* MODAL */}
        <Dialog draggable={false} visible={dialogVisibility} breakpoints={{'1900px': '60vw', '640px': '100vw'}}
            header="Ajouter nouveau sous admin" modal 
            className="p-fluid" onHide={hideDialog}>
            
            <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ handleChange,handleBlur, handleSubmit,isSubmitting, values, errors, touched, setFieldValue })=>{
               
                const isFormFieldValid = (name) => !!(touched[name] && errors[name]);
                const getFormErrorMessage = (name) => {
                    return isFormFieldValid(name) && <small className="p-error">{errors[name]}</small>;
                };
                return(
                <>
                {/* name admin */}
                <CustomInput
                label='nom et prénom'
                placeholder='nom et prénom'
                handleChange={handleChange}
                value={values.name}
                name='name'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                />

                {/* email */}
                <CustomInput
                label='émail'
                placeholder='émail'
                handleChange={handleChange}
                value={values.email}
                name='email'
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                />
                

                { /* password admin */ }
                <PasswordBlock
                handleChange={handleChange}
                isFormFieldValid={isFormFieldValid}
                getFormErrorMessage={getFormErrorMessage}
                password={values.password}/>
                
                
            <div className='mt-4 flex align-items-center justify-content-end'>
                <Button label="annuler" className='w-auto p-button-secondary p-button-sm p-button-outlined mr-2' icon="pi pi-times" 
                onClick={hideDialog}/>
                <Button onClick={handleSubmit} 
                label={isSubmitting?'loading':'sauvegarder'}
                disabled={isSubmitting}
                loading={isSubmitting}
                className='w-auto p-button-sm p-button-success' 
                icon="pi pi-check"  type='submit' />
            </div>
            </>
                )
            }}
            </Formik>
            
            </Dialog>
        </>
    )
}

export default AddAdmin