import Api from "../utils/Api";


export class BrandsService {

  // GET ALL SUB CATEGORIES
  async getSubCategories() {
    let response  = {}
    try {
      const subCategory = await Api.get('underCategory/all')
      response.data = subCategory.data
      return response
    } catch (error) {
      console.log("error : ",error)
      response.error = error
      return response
    }
  }


  // UPDATE SUB CATEGORY
  async updateSubCategory (_id,data) {
    let response = {}
    try {
      const updatedSubCategory = await Api.put(`underCategory/update/${_id}`,data)
      response.data = updatedSubCategory.data
      return response
    } catch (error) {
      console.log("error : ",error.message)
      response.error = error
      return response
    }
  }

  // ADD SUB CATEGORY
  async createSubCategory (data){
    let response = {}
    try {
      const createSubCate = await Api.post(`underCategory/create`,data)
      response.data = createSubCate.data
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

  async getBrandsByCategory(selectedCategories){
    let response = {}
    let _selectedCategories = ``
    selectedCategories.map((sc, index) => {
      if( (index + 1) == selectedCategories.length )  _selectedCategories+= sc
      else _selectedCategories+= sc + `,`
    })

    try {
      const brands = await Api.get(`underCategory?category=${_selectedCategories}`)
      response.data = brands.data
      return response
    } catch (error) {
      console.log(error)
      response.error = error
      return response
    }
  }

  async applyRemiseByUnderCategory(underCategoryId, remise){
    let response = {}
    try {
      await Api.put(`underCategory/remise/${underCategoryId}`,{remise})
      response.data = "remise appliquée avec succès"
      return response
    } catch (error) {
      response.error = error
      return response
    }
  }

}